import styled from 'styled-components';
import { Link } from 'gatsby';
import { EventNote, AccessTime } from '@styled-icons/material';

import { Body, Headline, Header } from './typography';
import { below, Wrapper } from './utils';

export const BlogHeader = styled(Header)`
	margin-bottom: 20px;
`;

export const BlogHeadline = styled(Headline)`
	margin-bottom: 35px;
`;

export const PostFeed = styled.section`
	margin-top: 20px;
	display: grid;
	justify-content: space-between;
	grid-gap: 50px 30px;
	grid-template-columns: 1fr 1fr 1fr;

	${below.md`
    grid-template-columns: 1fr 1fr;
  `};
	${below.sm`
    grid-template-columns: 1fr;
  `};
`;

export const PostCardContainer = styled(Link)`
	color: inherit;
	text-decoration: none;
	border-radius: 25px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden;
	background: ${({ theme }) => theme.secondaryColor};

	&:hover {
		text-decoration: none;
	}
`;

export const PostCardImage = styled.div`
	margin: 0 0 10px 0;
	width: auto;
	height: 180px;
	background: ${({ theme }) => theme.secondaryColor} no-repeat center center;
	background-size: cover;
	background-image: ${({ url }) => `url(${url})`};
`;

export const PostCardTags = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 20px 5px 15px;
	font-size: 0.9rem;
	color: ${({ theme }) => theme.grayScaleColor};

	& span {
		background: ${({ theme }) => theme.grayScaleColor + '60'};
		border-radius: 5px;
		padding: 5px 10px;
		margin: 5px 10px;
	}
`;

export const PostCardTitle = styled(Headline)`
	margin: 0 15px;
	font-weight: 700;
	padding: 0;
`;

export const PostCardExcerpt = styled(Body)`
	line-height: 1.4em;
	margin: 10px 15px;
	color: ${({ theme }) => theme.grayScaleFadedColor};
`;

export const PostCardFooter = styled.footer`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 10px 15px 20px;
	color: ${({ theme }) => theme.grayScaleColor + '70'};
	font-size: 0.8em;
`;

export const PostIconClock = styled(AccessTime)`
	width: 13px;
	margin: 0 2px 2px 0;
	color: ${({ theme }) => theme.grayScaleColor + '70'};
`;

export const PostIconCalender = styled(EventNote)`
	width: ${({ width }) => width ?? '13px'};
	margin: 0 2px 2px 0;
	color: ${({ theme }) => theme.grayScaleColor + '70'};
`;

export const PostCardFooterLeft = styled.div`
	display: flex;
	align-items: center;
`;

export const PostCardFooterRight = styled.div`
	display: flex;
	flex-direction: column;
`;

export const PostCardAvatar = styled.div`
	width: 30px;
	height: 30px;
	margin: 0 7px 0 0;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	& .author-profile-image {
		display: block;
		width: 100%;
		background: ${({ theme }) => theme.secondaryColor};
		border-radius: 100%;
		object-fit: cover;
	}

	& .default-avatar {
		width: 26px;
	}
`;

// Single Post Page
// ===================================
export const PostContent = styled.article`
	margin: 0 auto;
`;

export const PostContentDate = styled(Body)`
	color: ${({ theme }) => theme.grayScaleColor + '70'};
`;

export const PostFeatureImg = styled.figure`
	margin: 20px 0 0 !important;
	& img {
		margin: 0 0 3vw;
		width: 100%;
		height: 300px;
		object-fit: cover;
		border-radius: 25px;
		${below.sm`
      height: 200px;
    `};
	}
`;

export const PostContentHeader = styled(Wrapper)`
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
  margin-bottom: 10px;
  ${below.sm`
  margin-bottom: 20px;
  `};

	/* ${below.sm`
    flex-direction: column-reverse;
  `}; */
`;

export const PostContentTags = styled.div`
	margin: auto 0;
	font-size: 0.9rem;
	display: flex;
	flex-direction: row;
	/* justify-content: center; */
	color: ${({ theme }) => theme.grayScaleColor};

	& span {
		display: block;
		background: ${({ theme }) => theme.grayScaleColor + '60'};
		border-radius: 5px;
		padding: 5px 10px;
		margin: 5px 10px;
	}
`;

export const PostFullContent = styled.section`
	max-width: 720px;
	margin: 0 auto;
`;

export const PostContentTitle = styled(Header)`
	margin: 0 0 10px;
	${below.sm`
    margin-top: 15px;
  `};
`;

export const PostContentAuthor = styled(Wrapper)`
	border-top: 1px solid ${({ theme }) => theme.grayScaleColor + '70'};
	margin: 20px 0;
	padding-top: 30px;
	flex-direction: row;
	align-items: center;

	${below.sm`
    flex-direction: column;

    & p {
      text-align: center !important;
    }
  `};
`;

export const PostContentAvatar = styled.div`
	width: 140px;
	height: 140px;
	margin: 0 30px 0 0;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({ theme }) => theme.secondaryColor};
	${below.sm`
    margin: 0 0 10px;
  `};

	& .author-profile-image {
		display: block;
		width: 100%;
		background: ${({ theme }) => theme.secondaryColor};
		border-radius: 100%;
		object-fit: cover;
	}

	& .default-avatar {
		width: 80px;
	}
`;

export const PostContentAuthorLink = styled(Link)`
	color: ${({ theme }) => theme.accentSecondaryColor};
	text-decoration: none;
	margin: 10px 0 0;
	${below.sm`
    margin-top: 5px;
  `};

	&:hover {
		text-decoration: none;
	}
`;

export const PostContentBody = styled.section`
	display: flex;
	flex-direction: column;
	color: ${({ theme }) => theme.grayScaleColor + 'c0'};

	/* Reset
  /* ---------------------------------------------------------- */

	table {
		border-spacing: 0;
		border-collapse: collapse;
	}
	img {
		max-width: 100%;
	}
	a:active,
	a:hover {
		outline: 0;
	}
	h1 {
		margin: 0.67em 0;
		font-size: 2em;
	}
	small {
		font-size: 80%;
	}
	sub,
	sup {
		position: relative;
		font-size: 75%;
		line-height: 0;
		vertical-align: baseline;
	}
	sup {
		top: -0.5em;
	}
	sub {
		bottom: -0.25em;
	}
	img {
		border: 0;
	}
	svg:not(:root) {
		overflow: hidden;
	}
	mark {
		background-color: ${({ theme }) => theme.secondaryColor};
	}
	code,
	kbd,
	pre,
	samp {
		font-size: 1em;
	}
	button,
	input,
	optgroup,
	select,
	textarea {
		margin: 0;
		color: inherit;
		font: inherit;
	}
	button {
		overflow: visible;
		border: none;
	}
	button,
	select {
		text-transform: none;
	}
	button,
	html input[type='button'],
	input[type='reset'],
	input[type='submit'] {
		cursor: pointer;
	}
	button[disabled],
	html input[disabled] {
		cursor: default;
	}
	button::-moz-focus-inner,
	input::-moz-focus-inner {
		padding: 0;
		border: 0;
	}
	input {
		line-height: normal;
	}
	input:focus {
		outline: none;
	}
	input[type='checkbox'],
	input[type='radio'] {
		box-sizing: border-box;
		padding: 0;
	}
	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		height: auto;
	}
	input[type='search'] {
		box-sizing: content-box;
	}
	input[type='search']::-webkit-search-cancel-button,
	input[type='search']::-webkit-search-decoration {
		-webkit-appearance: none;
	}
	legend {
		padding: 0;
		border: 0;
	}
	textarea {
		overflow: auto;
	}
	table {
		border-spacing: 0;
		border-collapse: collapse;
	}
	td,
	th {
		padding: 0;
	}

	/* Defaults
  /* ---------------------------------------------------------- */

	hr {
		position: relative;
		display: block;
		width: 100%;
		margin: 1.8em 0 2.4em;
		padding: 0;
		height: 1px;
		border: 0;
		border-top: 1px solid ${({ theme }) => theme.grayScaleFadedColor};
	}

	audio,
	canvas,
	iframe,
	img,
	svg,
	video {
		vertical-align: middle;
	}

	fieldset {
		margin: 0;
		padding: 0;
		border: 0;
	}

	textarea {
		resize: vertical;
	}

	p,
	ul,
	ol,
	dl,
	blockquote {
		margin: 0 0 1.5em 0;
	}

	ol,
	ul {
		padding-left: 1.3em;
		padding-right: 1.5em;
	}

	ol ol,
	ul ul,
	ul ol,
	ol ul {
		margin: 0.5em 0 1em;
	}

	ul {
		list-style: disc;
	}

	ol {
		list-style: decimal;
	}

	ul,
	ol {
		max-width: 100%;
	}

	li {
		margin: 0.5em 0;
		padding-left: 0.3em;
		line-height: 1.6em;
	}

	dt {
		float: left;
		margin: 0 20px 0 0;
		width: 120px;
		font-weight: 500;
		text-align: right;
	}

	dd {
		margin: 0 0 5px 0;
		text-align: left;
	}

	blockquote {
		margin: 0.3em 0 1.8em;
		padding: 0 1.6em 0 1.6em;
		border-left-width: 10px;
		border-left-style: solid;
		border-left-color: ${({ theme }) => theme.accentSecondaryColor};
	}

	blockquote p {
		margin: 0.8em 0;
		font-size: 1.2em;
		font-weight: 300;
	}

	blockquote small {
		display: inline-block;
		margin: 0.8em 0 0.8em 1.5em;
		font-size: 0.9em;
		opacity: 0.8;
	}
	/* Quotation marks */
	blockquote small:before {
		content: '\2014 \00A0';
	}

	blockquote cite {
		font-weight: bold;
	}
	blockquote cite a {
		font-weight: normal;
	}

	a {
		color: ${({ theme }) => theme.accentSecondaryColor};
		text-decoration: none;
	}

	a:hover {
		text-decoration: underline;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 0;
		color: ${({ theme }) => theme.grayScaleColor};
		line-height: 1.15;
		font-weight: 700;
		font-family: 'Conv_EuclidCircularB-Regular';
		text-rendering: optimizeLegibility;
	}

	h1 {
		margin: 1em 0 0.5em 0;
		font-size: 2.2rem;
	}

	h2 {
		margin: 0.8em 0 0.4em 0;
		font-size: 2rem;
	}

	h3 {
		margin: 0.5em 0 0.2em 0;
		font-size: 1.8rem;
	}

	h4 {
		margin: 0.5em 0 0.2em 0;
		font-size: 1.4rem;
	}

	${below.sm`
    @media (max-width: 500px) {
      h1 {
        font-size: 1.8rem;
      }
      h2 {
        font-size: 1.6rem;
      }
      h3 {
        font-size: 1.2rem;
      }
      h4 {
        font-size: 1.2rem;
      }
    }
  `};

	h5 {
		display: block;
		margin: 0.5em 0;
		padding: 1em 0 1.5em;
		border: 0;
		font-family: Georgia, serif;
		font-style: italic;
		font-size: 2.2rem;
		text-align: center;
	}

	h6 {
		margin: 0.5em 0 0.2em 0;
		font-size: 1rem;
	}

	figure {
		margin: 0.4em 0 1.6em;
		font-size: 2.8rem;
		font-weight: 700;
	}

	figcaption {
		margin: 5px 0 0;
		font-size: 0.9rem;
	}

	pre {
		margin: 0.4em 0 1.8em;
		font-size: 0.8rem;
		line-height: 1.4em;
		white-space: pre-wrap;
		padding: 20px;
		background: ${({ theme }) => theme.secondaryColor};
		color: #fff;
		border-radius: 12px;
	}

	/* Koenig Styles
  /* ---------------------------------------------------------- */

	.kg-bookmark-card {
		width: 100%;
		margin-top: 0;
		background: ${({ theme }) => theme.secondaryColor};
	}

	.kg-bookmark-container {
		display: flex;
		min-height: 148px;
		color: ${({ theme }) => theme.grayScaleFadedColor};
		text-decoration: none;
		border-radius: 3px;
		overflow: hidden;
		box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.09);
	}

	.kg-bookmark-container:hover {
		color: ${({ theme }) => theme.grayScaleFadedColor};
		text-decoration: none;
		box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.09);
	}

	.kg-bookmark-content {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 20px;
	}

	.kg-bookmark-title {
		color: color(${({ theme }) => theme.accentSecondaryColor} l(-30%));
		font-size: 1rem;
		transition: color 0.2s ease-in-out;
	}

	.kg-bookmark-container:hover .kg-bookmark-title {
		color: ${({ theme }) => theme.accentSecondaryColor};
	}

	.kg-bookmark-description {
		display: -webkit-box;
		overflow-y: hidden;
		margin-top: 12px;
		max-height: 48px;
		color: color(${({ theme }) => theme.accentSecondaryColor} l(-10%));
		font-size: 0.9rem;
		line-height: 1.5em;
		font-weight: 400;

		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.kg-bookmark-thumbnail {
		position: relative;
		min-width: 33%;
		max-height: 100%;
	}

	.kg-bookmark-thumbnail img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 0 3px 3px 0;
		object-fit: cover;
	}

	.kg-bookmark-metadata {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-top: 14px;
		color: color(${({ theme }) => theme.accentSecondaryColor} l(-10%));
		font-size: 1.1rem;
		font-weight: 400;
	}

	.kg-bookmark-icon {
		margin-right: 8px;
		width: 22px;
		height: 22px;
	}

	.kg-bookmark-author {
		line-height: 1.5em;
	}

	.kg-bookmark-author:after {
		content: '•';
		margin: 0 6px;
	}

	.kg-bookmark-publisher {
		overflow: hidden;
		max-width: 240px;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	/* Gallery Styles
  /* ---------------------------------------------------------- */
	.kg-gallery-container {
		display: flex;
		flex-direction: column;
		max-width: 1040px;
		width: 100%;
	}

	.kg-gallery-row {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.kg-gallery-image img {
		display: block;
		margin: 0;
		width: 100%;
		height: 100%;
	}

	.kg-gallery-row:not(:first-of-type) {
		margin: 0.75em 0 0 0;
	}

	.kg-gallery-image:not(:first-of-type) {
		margin: 0 0 0 0.75em;
	}

	.kg-gallery-card + .kg-image-card.kg-width-wide,
	.kg-gallery-card + .kg-gallery-card,
	.kg-image-card.kg-width-wide + .kg-gallery-card,
	.kg-image-card.kg-width-wide + .kg-image-card.kg-width-wide {
		margin: -2.25em 0 3em;
	}

	/* Image Styles
  /* ---------------------------------------------------------- */
	.kg-card.kg-image-card img {
		display: block;
		max-width: 100%;
		/* max-height: 95px; */
		width: auto;
		height: auto;
		margin: 0 auto;
	}
`;
