import styled from 'styled-components';

import { rotateOut, fadeIn } from './keyframes';
import { below } from './utils';
import { HeadlineHome, HeaderHome } from './home';

export const Circles = styled.img`
	position: absolute;
	top: -150px;
	right: 0;
	left: 0;
	width: 550px;
	margin: auto;
	${below.sm`
    width: 340px;
    top: -50px;
  `};

	&.view6 {
		animation: ${rotateOut} 1s linear 1.5s both;
	}
`;

export const RewardImg = styled.img`
	width: 400px;
	padding-bottom: 50px;
	padding-top: 20px;
	${below.xxs`
    width: 320px;
  `};
	${below.xxs`
    width: ${({ iOS }) => (iOS ? '280px' : '320px')};
  `};
	&.view6 {
		animation: ${fadeIn} 1s linear 3s both;
	}
`;

export const RewardContainer = styled.div`
	position: relative;
`;

export const HeadlineReward = styled(HeadlineHome)`
	width: 420px;
	margin: 0 auto;
	${below.sm`
    width: 330px;
  `};
`;

export const HeaderReward = styled(HeaderHome)`
	${below.xs`
    margin-bottom: 10px;
    line-height: 1.2;
  `};
	${below.xxs`
    line-height: ${({ iOS }) => (iOS ? '1.1' : '1.2')};
    margin-bottom: ${({ iOS }) => (iOS ? '7px' : '10px')};
  `};
`;
