import styled from 'styled-components';
import { KeyboardArrowUp } from '@styled-icons/material';

import { fadeIn } from './keyframes';

export const ScrollToTopContainer = styled.div`
	background: ${({ theme }) => theme.secondaryColor};
	z-index: 2;
	position: fixed;
	bottom: 20px;
	right: 20px;
	border-radius: 15px;
	color: ${({ theme }) => theme.accentColor};
	padding: 10px;
	transition: 0.5s;
	animation: 0.5s ${fadeIn} linear 0s;
	cursor: pointer;

	&:hover {
		transition: 0.5s;
		background: ${({ theme }) => theme.accentColor};
		color: ${({ theme }) => theme.primaryColor};
	}
`;

export const ScrollTopArrow = styled(KeyboardArrowUp)`
	width: 25px;
`;
