import React, { useState, useEffect } from 'react';
import { useWindowScroll } from 'react-use';

import { ProgressContainer, ProgressFill } from '../../styles';

export default function ScrollIndicator({ postRef }) {
	const [scrollTop, setScrollTop] = useState(0);
	const { y: pageYOffset } = useWindowScroll();

	useEffect(() => {
		const winHeight = window.innerHeight;
		const clientHeight = postRef?.current?.clientHeight + 110;
		const height = (clientHeight ?? document.documentElement.clientHeight) - winHeight;
		const scrolled = (pageYOffset / height) * 100;

		setScrollTop(scrolled);
	}, [pageYOffset]);

	return (
		<ProgressContainer style={{ opacity: `${scrollTop === 0 ? '0' : '1'}` }}>
			<ProgressFill style={{ width: `${scrollTop}%` }} />
		</ProgressContainer>
	);
}
