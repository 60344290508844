import styled from 'styled-components';

import { fadeIn } from './keyframes';
import { below, Wrapper } from './utils';
import { Body, Header } from './typography';
import { GradientButton } from './buttons';

export const TestingContainer = styled(Wrapper)`
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 50px 0 70px;

	${below.sm`
    flex-direction: ${({ reverse }) => (reverse ? 'column-reverse' : 'column')};
    margin: 40px 0 10px;
  `};
`;

export const TestingInnerContainer = styled(Wrapper)`
	width: 50%;

	${below.sm`
    width: 95%;
  `};
`;

export const TestingImg = styled.img`
	width: 80%;
	margin: 0 auto;

	${below.sm`
    margin-bottom: 20px;
  `};

	${below.xs`
    width: 100%;
  `};
`;

export const TestingFormContainer = styled(Wrapper)`
	align-items: center;
`;

export const TestingFormButton = styled(GradientButton)`
	margin-top: 30px;

	background: ${({ theme, disabled }) => (disabled ? theme.accentFadedColor : theme.accentColor)};
`;

export const TestingFormTitle = styled(Body)`
	margin: 0 0 30px;
`;

export const TestingTermsText = styled.span`
	color: ${({ theme }) => theme.accentColor};
	cursor: pointer;
`;

export const TestingFormRow = styled(Wrapper)`
	flex-direction: row;

	${below.sm`
    flex-direction: column;
  `};
`;

export const TestingFormItem = styled.div`
	width: 100%;

	&.left {
		margin-right: 7px;
		${below.sm`
    margin-right: 0;
    `};
	}
	&.right {
		margin-left: 7px;
		${below.sm`
    margin-left: 0;
    `};
	}

	/* & input[type='date']:before {
		color: ${props => props.theme.grayScaleFadedColor};
		content: ${({ dob }) => (!dob ? 'attr(placeholder) !important' : '')};
		margin-right: 10px;
	} */

	input.PhoneInputInput {
		padding: 6px 12px;
		margin-bottom: 15px;
		font-size: 16px;
		height: 43px;
		font-family: 'Conv_EuclidCircularB-Regular';
		outline: none;
		background: ${({ theme }) => theme.grayScaleVeryFadedColor};
		color: ${({ theme }) => theme.grayScaleColor};
		border: none;
		border-radius: 10px;
		box-sizing: border-box;
		&::placeholder {
			color: ${({ theme }) => theme.grayScaleFadedColor};
		}
		${below.md`
      margin-bottom: 20px;
    `};
		${below.xs`
      margin-bottom: 10px;
    `};
	}
	.PhoneInput {
		width: 100%;
	}
	.PhoneInputCountryIcon {
		width: 35px;
		height: inherit;
	}
	.PhoneInputCountry {
		display: none;
	}
`;

export const TestingTab = styled(Body)`
	margin: 0 20px;
	color: ${({ selected, theme }) => (selected ? theme.grayScaleColor : theme.grayScaleFadedColor)};
	cursor: pointer;

	&:after {
		content: '';
		display: ${({ selected }) => (selected ? 'block' : 'none')};
		margin: 0 auto;
		width: 50%;
		padding-top: 4px;
		border-bottom: 2px solid ${({ theme }) => theme.accentColor};
		border-bottom-left-radius: 1px;
		border-bottom-right-radius: 1px;
	}
`;

export const SuccessMarkImg = styled.img`
	width: 120px;
	height: 120px;
	margin: 0 auto 20px;
`;

export const SuccessModalButton = styled(GradientButton)`
	margin: 20px auto 0;
	padding-right: 80px !important;
	padding-left: 80px !important;
	${below.sm`
    padding-right: 60px !important;
    padding-left: 60px !important;
  `};
`;

export const SuccessModalText = styled(Body)`
	max-width: 400px;
`;

// export const PhoneForm = styled(Form)`
// 	--PhoneInputCountrySelectArrow-color--focus: ${props => props.theme.accentColor};
// 	--PhoneInputCountryFlag-borderColor--focus: ${props => props.theme.accentColor};
// 	--PhoneInputCountrySelectArrow-color: ${props => props.theme.grayScaleColor};
// 	--PhoneInputCountrySelect-marginRight: 8px;

// 	padding-right: 70px;
// 	position: relative;
// 	${below.md`
//     max-width: 360px;
//   `};

// 	input.PhoneInputInput {
// 		min-width: 100px;
// 		padding: 6px 12px;
// 		font-size: 16px;
// 		height: 43px;
// 		font-family: 'Conv_EuclidCircularB-Regular';
// 		outline: none;
// 		background: rgba(255, 255, 255, 0.1);
// 		color: ${({ theme }) => theme.grayScaleColor};
// 		border: none;
// 		border-radius: 10px;
// 		box-sizing: border-box;
// 		&::placeholder {
// 			color: ${({ theme }) => theme.grayScaleFadedColor};
// 		}
// 	}

// 	.PhoneInput {
// 		width: 100%;
// 	}

// 	.PhoneInputCountryIcon {
// 		width: 35px;
// 		height: inherit;
// 	}

// 	.PhoneInputCountry {
// 		display: none;
// 	}
// `;
