import styled from 'styled-components';
import { below } from './utils';

export const CardsImg = styled.img`
	position: absolute;
	right: 50px;
	top: 25%;
	width: 50%;
	margin: 10px auto;
	${below.md`
    width: 60%;
    top: 20%;
    right: 8%;
  `};
	${below.xxs`
    right: 3%;
  `};
`;

export const CardHeader = styled.div`
	margin-bottom: -40px;
	${below.md`
  `};
	${below.xxs`
  `};
`;
