import React from 'react';

export const iOS = () => {
	var result = typeof window !== 'undefined' ? /^(iPhone|iPad|iPod|Mac)/.test(navigator.platform) : false;
	return result;
};

export const amount = n => Math.round(n).toLocaleString('en-US');

export const useWindowSize = () => {
	const breakWidth = 768;
	const safeWindow = typeof window !== 'undefined' ? window : { innerWidth: 1200, innerHeight: 800 };

	const [windowSize, setWindowSize] = React.useState({
		width: safeWindow.innerWidth,
		height: safeWindow.innerHeight,
		isDesktop: safeWindow.innerWidth > breakWidth,
	});

	function changeWindowSize() {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
			isDesktop: window.innerWidth > breakWidth,
		});
	}

	React.useEffect(() => {
		window.addEventListener('resize', changeWindowSize);

		return () => {
			window.removeEventListener('resize', changeWindowSize);
		};
	}, []);

	return windowSize;
};

export const extLinkClick = url => (typeof window !== 'undefined' ? window.location.assign(url) : false);

export const openInNewTab = url => {
	const newWindow = typeof window !== 'undefined' ? window.open(url, '_blank', 'noopener,noreferrer') : false;
	if (newWindow) newWindow.opener = null;
};

export const getSectionContent = (num, text, content) =>
	content?.find(({ node: { position } }) => position === `section${num}-${text}`)?.node;
