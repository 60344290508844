import styled from 'styled-components';
import { Link } from 'gatsby';

import { Wrapper, below, getColor, above } from './utils';

export const HorRule = styled.hr`
	border: 0;
	margin-top: 50px;
	height: 2px;
	background: ${({ theme }) => theme.grayScaleVeryFadedColor};
`;

export const FootMedia = styled.a`
	margin: 0 6px;
	color: #fff;
	${below.md`
    margin: 0 3px;
  `};
`;

export const FootImg = styled.img`
	margin: 3px 0;
	height: 33px;
	width: 33px;
`;

export const FooterSections = styled(Wrapper)`
	margin-top: 30px;
	margin-bottom: 40px;
	height: auto;
	${below.md`
    justify-content: space-evenly;
  `};
	${below.xs`
    justify-content: space-between;
  `};
`;

export const FooterSection = styled(Wrapper)`
	margin-top: 20px;
	flex-basis: 18%;
	height: auto;
	text-align: left;
	${below.md`
    flex-basis: 34%;
    margin-top: 30px;
    margin-left: 100px;
  `};
	${below.sm`
    margin-left: 50px;
  `};
	${below.xs`
    margin-left: 0;
    flex-basis: 45%;
  `};

	&.hide-sm {
		${below.md`
      display: none;
    `};
	}

	&.hide-lg {
		flex-basis: 100%;
		text-align: center;
		${above.md`
      display: none;
    `};
	}
`;

export const FooterBrand = styled(Link)`
	cursor: pointer;
`;

export const FooterLogo = styled.img`
	width: 105px;
`;

export const FooterLink = styled(Link)`
	cursor: pointer;
	color: ${({ theme }) => theme.grayScaleColor};
	font-size: 14px;
`;

export const FooterLinkExt = styled.a`
	cursor: pointer;
	color: ${({ theme }) => theme.grayScaleColor};
	font-size: 14px;
	margin: 0;
	padding: 0;
`;

export const Foot = styled.p`
	font-size: 14px;
	margin: 0;
	color: ${props => getColor(props)};
`;

export const FootGrey = styled.div`
	font-size: 14px;
	color: ${({ theme }) => theme.grayScaleFadedColor};
	margin: 10px 10px;
	text-align: ${({ alignLeft }) => (alignLeft ? 'left' : 'center')};
	max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '70%')};
	${below.lg`
    max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '80%')};
  `};
	${below.sm`
    max-width: 100%;
  `};

	& ul {
		list-style-type: circle;
	}
`;

export const FootGreyWrapper = styled(Wrapper)`
	height: auto;
`;

export const FootHead = styled.p`
	margin-top: 0;
	color: ${props => getColor(props)};
	font-size: 20px;
	font-family: 'Conv_EuclidCircularB-Bold';
`;

export const FooterContainer = styled.div`
	padding-bottom: 80px;
`;

export const FootGreyLink = styled(Link)`
	color: ${({ theme }) => theme.grayScaleFadedColor};
	text-decoration: underline;
`;
