import styled, { keyframes } from "styled-components";
import { fadeInUp } from "react-animations";
import { below, Wrapper } from "./utils";
import { HeaderHome } from "./home";

const zoomInAnimation = keyframes`${fadeInUp}`;

export const AccountsImg = styled.img`
	width: 60%;
	margin: 10px auto;
	${below.md`
    width: 80%;
  `};
`;
