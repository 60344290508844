import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import logo from '../../images/home/logo_clean.png';
import stars from '../../images/home/testimonials/4_half_stars.png';
import { extLinkClick } from '../../utils/general';
import {
	DownloadModalContainer,
	DownloadModalButton,
	DownloadModalBody,
	DownloadModalLogoContainer,
	DownloadModalStars,
	DownloadModalLogo,
	DownloadModalText,
	DownloadModalCaption,
} from '../../styles';

const DownloadModal = () => (
	<StaticQuery
		query={graphql`
			query {
				allGoogleContentNewSheet {
					edges {
						node {
							position
							text
							text1
							text2
							text3
							text4
							text5
						}
					}
				}
			}
		`}
		render={({ allGoogleContentNewSheet }) => {
			const sectionContent = section =>
				allGoogleContentNewSheet.edges.filter(({ node: { position } }) => position?.includes(`section${section}`));
			const getContent = text =>
				sectionContent(1).find(({ node: { position } }) => position === `section${'1'}-${text}`).node;

			return (
				<DownloadModalContainer>
					<DownloadModalLogoContainer>
						<DownloadModalLogo src={logo} alt="logo" />
					</DownloadModalLogoContainer>
					<DownloadModalText>
						<DownloadModalBody>Envel</DownloadModalBody>
						<DownloadModalStars src={stars} alt="stars rating" />
						<DownloadModalCaption></DownloadModalCaption>
						{/* <DownloadModalCaption>{getContent('downloads')?.text}</DownloadModalCaption> */}
					</DownloadModalText>
					<DownloadModalButton onClick={() => extLinkClick('https://envel.app.link/get')}>
						Get the app
					</DownloadModalButton>
				</DownloadModalContainer>
			);
		}}
	/>
);

export default DownloadModal;
