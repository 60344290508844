import styled from 'styled-components';
import { below } from './utils';

export const ProgressContainer = styled.div`
	background: ${({ theme }) => theme.secondaryColor};
	height: 5px;
	position: sticky;
	top: 86px;
	left: 0;
	z-index: 1;
	width: 100%;
	overflow: hidden;
	${below.md`
    top: 70px;
  `};
`;

export const ProgressFill = styled.div`
	background: ${({ theme }) => theme.accentSecondaryColor};
	height: 5px;
`;
