import styled from "styled-components";

export const CookieImg = styled.img`
	width: 40px;
	margin: 15px 0 5px;
`;

export const CookieSpan = styled.span`
	font-size: 19px;
`;

export const contentStyle = {
	color: `#000`,
	flex: `1 0 180px`,
	margin: `0`,
	fontSize: `14px`,
	fontWeight: `400`,
	textAlign: `center`,
	fontFamily: `Conv_EuclidCircularB-Bold`,
};

export const style = {
	background: `#f1f0ff`,
	width: `210px`,
	height: `240px`,
	left: `none`,
	right: `0`,
	margin: `25px`,
	padding: `15px 15px 0`,
	justifyContent: `center`,
	borderRadius: `15px 15px 0 15px`,
	alignItems: `center`,
};

export const buttonStyle = {
	background: `#6955f3`,
	margin: `3px 25px`,
	padding: `10px 30px`,
	borderRadius: `7px`,
	fontWeight: `400`,
	color: `#f1f0ff`,
	fontFamily: `Conv_EuclidCircularB-Regular`,
};

export const declineButtonStyle = {
	background: `none`,
	margin: `3px 27px`,
	color: `#6955f3`,
	padding: `10px 20px`,
	fontWeight: `500`,
	borderRadius: `7px`,
	fontFamily: `Conv_EuclidCircularB-Regular`,
};
