import styled from 'styled-components';
import { below, Wrapper, above } from './utils';
import { HeaderHome } from './home';
import { Body } from './typography';

export const TestimonialsHeader = styled(HeaderHome)`
	${below.xs`
    font-size: 28px;
  `};
	${below.xxxs`
    font-size: 26px;
  `};
`;

export const StarsText = styled(Body)`
	margin-top: 20px;
	${below.sm`
    display: none;
  `};
`;

export const StarsCaption = styled(Body)`
	color: ${({ theme }) => theme.grayScaleFadedColor};
	font-size: 0.8em;
	margin: 6px 0 0;
	${below.xxs`
    margin: 4px 0 0;
  `};
`;

export const StarsMain = styled.img`
	width: 180px;
	margin-top: 20px;
`;

export const TestimonialStores = styled.img`
	height: 50px;
	cursor: pointer;
	/* margin-top: 20px; */

	&.right {
		margin-left: 10px;
	}
`;

export const StarsTest = styled.img`
	width: 100px;
	${below.sm`
    margin: 0 auto;
  `};
`;

export const TestimonialPage = styled.div`
	/* padding: 0 10px;
	${below.sm`
    padding: 0 15px;
  `}; */
	& .slick-next:before,
	.slick-prev:before {
		color: ${({ theme }) => theme.accentColor};
	}
	& .slick-next:before {
		content: ${({ right }) => `url(${right})`};
	}
	& .slick-prev:before {
		content: ${({ left }) => `url(${left})`};
	}
`;

export const Testimonial2Page = styled(TestimonialPage)`
	& .slick-next {
		${above.sm`
      display: none !important;
    `};
	}

	& .slick-prev {
		${above.sm`
      display: none !important;
    `};
	}
`;

export const DownloadButtons = styled(Wrapper)`
	flex-direction: row;
	justify-content: center;
	${below.sm`
    display: none;
  `};
`;

export const TestimonialSliderWrapper = styled(Wrapper)`
	margin-top: 50px;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	${below.sm`
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
  `};
`;

export const TestimonialSliderContainer = styled.div`
	width: 43%;
	${below.md`
  width: 60%;
  `};
	${below.sm`
    padding:15px;
    width: 93%;
  `};
`;

export const Testimonial2SliderContainer = styled.div`
	${below.sm`
    padding: 15px;
    width: 93%;
    margin: 15px auto 0;
  `};
	& .slick-list {
		overflow: visible !important;
		${below.sm`
      overflow: hidden !important;
    `};
	}
`;

export const SliderImages = styled(Wrapper)`
	width: 47%;
	flex-direction: row;
	flex-wrap: wrap;
	${below.sm`
    width: 100%;
    justify-content: center;
  `};
`;

export const SliderImage = styled.div`
	background-image: ${({ src }) => `url(${src})`};
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 160px;
	height: 200px;
	border-radius: 20px;
	margin: 5px;
	${below.lg`
    &:nth-of-type(5), &:nth-of-type(6) {
      display: none;
    }
  `};
	${below.md`
    &:nth-of-type(3), &:nth-of-type(4) {
      display: none;
    }
  `};
	${below.sm`
    display: ${({ show }) => (show ? 'block' : 'none')} !important;
  `};
	${below.xxxs`
    width: 135px;
  `};
`;

export const TestimonialWrapper = styled.div`
	padding: 0 10px;
	/* height: 290px; */
`;

export const TestimonialContainer = styled(Wrapper)`
	justify-content: center;
`;

export const Testimonial2Container = styled(TestimonialContainer)`
	border-radius: 10px;
	padding: 20px 20px 10px;
	background: ${({ theme }) => theme.secondaryColor};
	border-radius: 15px;
`;

export const TestimonialHeadline = styled(Body)`
	font-family: 'Conv_EuclidCircularB-Bold';
	${below.sm`
    text-align: center;
  `};
`;

export const TestimonialBody = styled(Body)`
	font-size: 0.85rem;
	opacity: 0.7;
`;

export const Testimonial2Body = styled(TestimonialBody)`
	margin-top: 0;
`;

export const TestimonialFooter = styled(Body)`
	font-size: 0.85rem;
	opacity: 1;
	${below.sm`
    text-align: center;
  `};
`;
