import styled from 'styled-components';
import { CheckCircleFill, Dot } from '@styled-icons/bootstrap';

import { below, Wrapper } from './utils';

export const ListItemImage = styled(Dot)`
	width: 40px;
	height: 40px;
	/* margin-right: 10px; */
	color: ${props => props.theme.accentColor};
`;

export const ListItemWrapper = styled(Wrapper)`
	& p {
		margin-top: 8px;
		margin-bottom: 8px;
	}
`;
