import styled, { keyframes } from "styled-components";
import { fadeIn, zoomInDown } from "react-animations";

import { below, Wrapper, above } from "./utils";
import { Body } from "./typography";
import { HeaderHome, HeadlineHome } from "./home";
import { InputField, Form } from "./formFields";
import { GradientButton } from "./buttons";

const fadeInAnimation = keyframes`${fadeIn}`;
const zoomInDownAnimation = keyframes`${zoomInDown}`;

export const HarvardText = styled.div`
	text-align: left;
	${below.sm`
    text-align: center;
  `};
`;

export const HarvardLogoImg = styled.img`
	width: 190px;
	${below.sm`
    width: 170px;
  `};

	&.mitLogo {
		width: 270px;
		${below.md`
      width: 220px;
    `};
		${below.sm`
      width: 170px;
    `};
	}
`;

export const HarvardLogoCont = styled(Wrapper)`
	width: auto;
	height: auto;
	margin: 15px 0;
	${below.sm`
    width: 100%;
    justify-content: center;
    margin: 10px 0;
  `};
`;

export const HeadlineHarvard = styled(HeadlineHome)`
	margin-right: 20px;
	font-weight: 100;
	${below.md`
    font-size: 17px;
    margin-right: 10px;
  `};
	${below.sm`
    font-size: 15px;
  `};
	${below.xxs`
    font-size: 14px;
    margin-right: 5px;
  `};
`;

export const HeaderHarvard = styled(HeaderHome)`
	width: 640px;
	font-size: 38px;
	margin-bottom: 20px;
	${below.md`
    width: 450px;
    font-size: 34px;
  `};
	${below.sm`
    font-size: 25px;
    width: 90%;
    text-align: center;
  `};
	${below.xs`
    width: 100%;
  `};

	&.subHeader {
		font-size: 25px;
		${above.sm`
      width: 400px;
    `};
		${above.md`
      width: 500px;
    `};
		${below.md`
      font-size: 20px;
    `};
	}
`;

export const HeaderYellow = styled.span`
	color: #ffa800;
`;

export const HarvardImgCont = styled(Wrapper)`
	justify-content: center;
	align-items: center;
	height: auto;
	${below.sm`
    overflow: hidden;
  `};
`;

export const HarvardImg = styled.img`
	:nth-of-type(1) {
		margin-top: 40px;
		z-index: 2;
		width: 75%;
		${below.lg`
      margin-top: 70px;
      width: 100%;
    `};
		${below.md`
      margin-top: 50px;
    `};
		${below.sm`
      margin: 30px 0;
      width: 70%;
    `};
	}
	:nth-of-type(2) {
		position: absolute;
		width: 140%;
		top: 60px;
		z-index: 1;
		${below.lg`
      width: 190%;
      top: 110px;
    `};
		${below.md`
      top: 130px;
    `};
		${below.sm`
      width: 130%;
      top: -30px;
    `};
	}

	&.view {
		animation: 0.8s ${zoomInDownAnimation} ease-in-out 0.7s both;
	}
`;

export const HarvardSection = styled(Wrapper)`
	height: auto;
	margin-bottom: 30px;
	${below.sm`
    flex-direction: column-reverse;
    margin-bottom: 0;
  `};

	animation: 1.5s ${fadeInAnimation} both;
`;

export const HarvardEmailWrapper = styled(Wrapper)`
	height: auto;
	${below.sm`
    align-items: center;
  `};
`;

export const EmailText = styled(Body)`
	text-align: center;
	margin: 0 0 0 15px;
	${below.sm`
    margin-left: 5px;
    font-size: 0.9rem;
  `};
`;

export const EmailInputHar = styled(InputField)`
	width: 50%;
	margin: 0;
	${below.md`
    margin: 0;
  `};
`;

export const EmailInputMIT = styled(EmailInputHar)`
	width: 65%;
`;

export const EmailFormHar = styled(Form)`
	width: 400px;
	position: relative;
	${below.md`
    width: 370px;
  `};
	${below.sm`
    width: 340px;
  `};
`;

export const SignUpButtonHar = styled(GradientButton)`
	margin: 15px 0 20px;
	width: 100%;
	display: none;
	${below.sm`
    margin-top: 10px;
  `};
	&:nth-of-type(1) {
		display: block;
	}

	&.done {
		&:nth-of-type(1) {
			display: none;
		}
		&:nth-of-type(2) {
			display: block;
		}
	}
`;
