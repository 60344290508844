import styled from 'styled-components';

import { Body } from './typography';
import { Wrapper, above } from './utils';

export const DownloadModalContainer = styled(Wrapper)`
	flex-direction: row;
	/* justify-content: space-evenly; */
	align-items: center;
	position: fixed;
	bottom: 0;
	height: auto;
	/* background: ${({ theme }) => theme.secondaryColor}; */
	background: rgba(0, 0, 0, 1);
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 1);
	padding: 4px 10px 4px;
	border-radius: 10px 10px 0 0;
	${above.sm`
    display: none;
  `};
`;

export const DownloadModalText = styled(Wrapper)`
	padding: 10px;
	width: auto;
	flex: 2;
	justify-content: center;
`;

export const DownloadModalButton = styled.div`
	padding: 10px 14px;
	background: ${({ theme }) => theme.accentColor};
	color: ${({ theme }) => theme.grayScaleColor};
	font-family: 'Conv_EuclidCircularB-Bold';
	border-radius: 10px;
	font-size: 0.9em;
`;

export const DownloadModalLogoContainer = styled.div`
	padding: 10px;
	background: ${({ theme }) => theme.secondaryColor};
	border-radius: 10px;
	line-height: 0;
`;

export const DownloadModalLogo = styled.img`
	height: auto;
	width: 40px;
`;

export const DownloadModalStars = styled.img`
	width: 90px;
	margin-top: 3px;
`;

export const DownloadModalBody = styled(Body)`
	margin: 0;
	font-family: 'Conv_EuclidCircularB-Bold';
`;

export const DownloadModalCaption = styled(Body)`
	margin: 5px 0 0;
	font-size: 0.7em;
	color: ${({ theme }) => theme.grayScaleFadedColor};
`;
