import styled from 'styled-components';

import { Body } from './typography';
import { Wrapper, below } from './utils';

export const ActivateQRImage = styled.img`
	width: 170px;
	height: 170px;
	margin: 10px;
`;

export const ActivateCardListNmr = styled(Body)`
	margin: 5px;
	margin-right: 10px;
	padding: 1px 8px;
	background: ${props => props.theme.primaryColor};
	border-radius: 50%;
`;

export const ActivateCardBody = styled(Body)`
	width: 80%;
	margin: 20px auto 20px;
	${below.sm`
    width: 90%;
  `};
`;

export const ActivateCardWrapper = styled(Wrapper)`
	border-radius: 25px;
	background: ${({ theme }) => theme.secondaryColor};
	padding: 30px;
	margin: 0 0 30px;
	${below.sm`
    flex-direction: column;
  `};
`;
