import styled from 'styled-components';
import { below, Wrapper } from './utils';
import { Body } from './typography';

export const Carousel = styled.div`
	margin: 10px auto 20px;
	/* ${below.sm`
  margin: 20px auto 50px;
  `}; */
`;

export const SlideWrapper = styled(Wrapper)`
	height: 60px;
	margin: 10px;
`;

export const SlideText = styled(Body)`
	font-size: 13.5px;
	margin: 0 0 2px;
	font-weight: 600;
	color: ${({ theme }) => theme.grayScaleFadedColor};
`;

export const Slide = styled.img`
	height: 30px;
	margin: 0 auto;

	&.mit {
		opacity: 0.7;
	}
`;
