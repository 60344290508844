import styled, { keyframes } from 'styled-components';
import { pulse, fadeInUp } from 'react-animations';

import { Wrapper, below } from './utils';
import { HeadlineHome, HeaderHome } from './home';
import { Headline } from './typography';
import {
	cashSwitch,
	emergencySwitch,
	vaultSwitch,
	billsSwitch,
	fadeInOut,
	fadeIn,
	coinsFall,
	coinsFallMed,
	bubbleClockwise,
	bubbleAntiClockwise,
	brainAntiClockwise,
	brainClockwise,
	fadeInOutBrain,
} from './keyframes';

const pulseAnimation = keyframes`${pulse}`;
const fadeInUpAnimation = keyframes`${fadeInUp}`;

export const AutoWrapper = styled(Wrapper)`
	width: auto;
	height: auto;
	${below.md`
    flex-direction: column;
  `};
`;

export const AutoAnimWrapper = styled(Wrapper)`
	width: 50%;
	height: auto;
	${below.md`
    width: 100%;
    align-items: center;
  `};
`;

export const AutoTextWrapper = styled(Wrapper)`
	width: 50%;
	height: auto;
	${below.md`
    width: 100%;
    align-items: center;
    top: -33vh;
  `};
`;

export const AutoText = styled.div`
	position: absolute;
`;

export const HeaderAuto = styled(HeaderHome)`
	${below.md`
    text-align: center;
  `};
	${below.xs`
    line-height: 1;
    margin-bottom: 10px;
  `};
	${below.xxs`
    font-size: ${({ iOS }) => (iOS ? '27px' : '31px')};
    margin-bottom: ${({ iOS }) => (iOS ? '5px' : '10px')};
  `};
`;

export const HeadlineAuto = styled(HeadlineHome)`
	${below.md`
    text-align: center;
  `};
	${below.xs`
    margin 0;
  `};
	${below.xxs`
    line-height: ${({ iOS }) => (iOS ? '1.1' : '1.4')};
    font-size: ${({ iOS }) => (iOS ? '17px' : '18px')};
  `};
`;

export const AnimText = styled(Headline)`
	text-align: center;
	font-size: 22px;
	width: 100%;
	&#brainText {
		margin-top: 130px;
	}
`;

export const Animation = styled.div`
	position: absolute;
	height: 100%;
	width: 100%;

	&.view {
		&#brain {
			animation: 2.1s ${fadeInOutBrain} ease-in-out 0.7s both;
		}
		&#coins {
			animation: 9s ${fadeInOut} ease-in-out 2.8s both;
		}
		&#check {
			animation: 0.3s ${fadeIn} linear 11.5s both;
		}
	}
`;

export const BrainImg = styled.img`
	position: absolute;
	&:nth-of-type(1) {
		top: -39px;
		left: calc(50% - 39px);
	}
	&:nth-of-type(2) {
		top: -71.5px;
		left: calc(50% - 71.5px);
	}
	&:nth-of-type(3) {
		top: -100px;
		left: calc(50% - 100px);
	}

	&.view {
		&:nth-of-type(2) {
			animation: 1.3s ${brainAntiClockwise} cubic-bezier(0.77, 0, 0.175, 1) 1.1s both;
		}
		&:nth-of-type(3) {
			animation: 1.3s ${brainClockwise} cubic-bezier(0.77, 0, 0.175, 1) 1.1s both;
		}
	}
`;

export const CheckImg = styled.img`
	position: absolute;
	width: 250px;
	top: calc(50% - 125px);
	left: calc(50% - 125px);

	&.view {
		animation: 0.7s ${pulseAnimation} 11.8s 2 both;
	}
`;

// ============================================
// COINS & ENVELOPES ANIMATION
// ============================================

export const CoinImg = styled.img`
	z-index: 15;
	position: absolute;
	&:nth-of-type(1) {
		width: 250px;
		top: calc(50% - 300px);
		left: calc(50% - 125px);
		${below.md`
      width: 180px;
      top: calc(50% - 190px);
      left: calc(50% - 90px);
    `};
		${below.xxs`
      top: calc(50% - 160px);
    `};
	}
	&:nth-of-type(2) {
		width: 250px;
		top: calc(50% - 300px);
		left: calc(50% - 125px);
		${below.md`
      width: 180px;
      top: calc(50% - 190px);
      left: calc(50% - 90px);
    `};
		${below.xxs`
      top: calc(50% - 160px);
    `};
	}
	&:nth-of-type(3) {
		top: 0;
		left: calc(50% - 30px);
		${below.md`
      top: 20px;
    `};
		${below.xxs`
      top: 20px;
    `};
	}

	&.view {
		&:nth-of-type(1) {
			animation: 8s ${bubbleClockwise} linear 3.9s both;
		}
		&:nth-of-type(2) {
			animation: 8s ${bubbleAntiClockwise} linear 3.9s both;
		}
		&:nth-of-type(3) {
			animation: 8s ${coinsFall} linear 3.9s both;
			${below.md`
        animation: 8s ${coinsFallMed} linear 3.9s both;
      `};
		}
	}

	&.viewCalc {
		&:nth-of-type(1) {
			animation: 8s ${bubbleClockwise} linear 0.9s both;
		}
		&:nth-of-type(2) {
			animation: 8s ${bubbleAntiClockwise} linear 0.9s both;
		}
		&:nth-of-type(3) {
			animation: 8s ${coinsFall} linear 0.9s both;
			${below.md`
        animation: 8s ${coinsFallMed} linear 0.9s both;
      `};
		}
	}
`;

export const AnimEnvelopes = styled.div`
	position: relative;
	top: 70px;
	margin: 0 auto;
	width: 250px;
	${below.md`
    top: 90px;
    width: 200px;
  `};
`;

export const AnimEnvelope = styled.div`
	position: absolute;
	width: 100%;

	&.view {
		&:nth-of-type(1) {
			animation: 8s ${cashSwitch} linear 3.2s both;
		}
		&:nth-of-type(2) {
			animation: 8s ${billsSwitch} linear 3.2s both;
		}
		&:nth-of-type(3) {
			animation: 8s ${vaultSwitch} linear 3.2s both;
		}
		&:nth-of-type(4) {
			animation: 8s ${emergencySwitch} linear 3.2s both;
		}
	}

	&.viewCalc {
		&:nth-of-type(1) {
			animation: 8s ${cashSwitch} linear 0.2s both;
		}
		&:nth-of-type(2) {
			animation: 8s ${billsSwitch} linear 0.2s both;
		}
		&:nth-of-type(3) {
			animation: 8s ${vaultSwitch} linear 0.2s both;
		}
		&:nth-of-type(4) {
			animation: 8s ${emergencySwitch} linear 0.2s both;
		}
	}
`;

export const BubbleText = styled(Headline)`
	position: absolute;
	font-size: 40px;
	z-index: 20;
	left: 0;
	text-align: center;
	right: 0;
	margin: 0 auto;
	top: calc(50% - 205px);
	${below.md`
    font-size: 30px;
    top: calc(50% - 120px);
  `};
	${below.xxs`
    top: calc(50% - 90px);
  `};

	&.view {
		&:nth-of-type(1) {
			animation: 0.7s ${fadeInOut} ease-in-out 3.2s both;
		}
		&:nth-of-type(2) {
			animation: 2s ${fadeInOut} ease-in-out 3.9s both;
		}
		&:nth-of-type(3) {
			animation: 2s ${fadeInOut} ease-in-out 5.9s both;
		}
		&:nth-of-type(4) {
			animation: 2s ${fadeInOut} ease-in-out 7.9s both;
		}
		&:nth-of-type(5) {
			animation: 0.3s ${fadeIn} ease-in-out 9.9s both;
		}
	}

	&.viewCalc {
		&:nth-of-type(1) {
			animation: 0.7s ${fadeInOut} ease-in-out 0.2s both;
		}
		&:nth-of-type(2) {
			animation: 2s ${fadeInOut} ease-in-out 0.9s both;
		}
		&:nth-of-type(3) {
			animation: 2s ${fadeInOut} ease-in-out 2.9s both;
		}
		&:nth-of-type(4) {
			animation: 2s ${fadeInOut} ease-in-out 4.9s both;
		}
		&:nth-of-type(5) {
			animation: 0.3s ${fadeIn} ease-in-out 6.9s both;
		}
	}
`;

// ============================================
// HOME SECTION 3 AUTOPILOTCHOICE
// ============================================

export const PilotWrapper = styled(Wrapper)`
	width: auto;
	height: auto;
	${below.sm`
    flex-direction: column;
    margin-bottom: 30px;
  `};

	&.view {
		animation: 1s ${fadeInUpAnimation} 1.5s both;
	}
`;

export const ImgPilot = styled.img`
	width: 300px;
	margin: 30px;
	${below.sm`
    margin: 0;
    width: 210px;
  `};
	${below.xxs`
    width: ${({ iOS }) => (iOS ? '190px' : '210px')};
  `};

	&:nth-of-type(2) {
		cursor: pointer;
		animation: 1.8s ${pulseAnimation} infinite;
	}
`;

export const HeadlinePilot = styled(HeadlineHome)`
	${below.sm`
    margin: 0;
    margin-top: 5px;
  `};
	${below.xxs`
    line-height: ${({ iOS }) => (iOS ? '1.1' : '1.3')};
  `};
`;
