import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { ModalProvider } from 'styled-react-modal';
import { useLocation } from '@reach/router';

import { GlobalStyle } from '../styles/globalStyles';
import { theme } from '../styles/theme';
import { Container, SpecialModalBackground, PagesContainer } from '../styles';
import DownloadModal from './website/DownloadModal';
import Header from './website/Header';
import Footer from './website/Footer';
import ScrollIndicator from './website/ScrollIndicator';
import { CustomThemeProvider } from '../providers/ThemeProvider';

/**
 * Main layout component
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 */
const DefaultLayout = ({ children, postRef, homeRef, footerText, footerAddText }) => {
	const location = useLocation();
	const { pathname } = location;

	const showDownload = () =>
		!pathname.startsWith('/blog/') &&
		!pathname.startsWith('/referrals-terms/') &&
		!pathname.startsWith('/terms/') &&
		!pathname.startsWith('/privacy/');

	return (
		<CustomThemeProvider>
			<ThemeProvider theme={theme}>
				<ModalProvider backgroundComponent={SpecialModalBackground}>
					<GlobalStyle />
					<Header homeRef={homeRef} />
					{postRef && <ScrollIndicator postRef={postRef} />}
					<Container>
						{location?.pathname !== '/' ? (
							<PagesContainer>
								<main>{children}</main>
							</PagesContainer>
						) : (
							<main>{children}</main>
						)}
						<Footer footerText={footerText} footerAddText={footerAddText} />
						{/* <Cookie /> */}
					</Container>
					{showDownload() && <DownloadModal />}
				</ModalProvider>
			</ThemeProvider>
		</CustomThemeProvider>
	);
};

DefaultLayout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default DefaultLayout;
