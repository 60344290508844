import styled, { keyframes } from "styled-components";
import { fadeInUp } from "react-animations";

import { fadeInOutLive, fadeIn } from "./keyframes";
import { Wrapper, below } from "./utils";
import { HeaderHome, HeadlineHome } from "./home";

const fadeInUpAnimation = keyframes`${fadeInUp}`;

export const HeaderLive = styled(HeaderHome)`
	${below.md`
    text-align: center
  `};
`;

export const HeadlineLive = styled(HeadlineHome)`
	${below.md`
    text-align: center
  `};
`;

export const LiveText = styled.div`
	width: 45%;
	${below.md`
    width: 80%;
    margin-bottom: 20px;
  `};
	${below.sm`
    width: 95%;
    margin-bottom: 0;
  `};
`;

export const LiveWrapper = styled(Wrapper)`
	height: auto;
	flex-direction: row-reverse;
	justify-content: space-evenly;
	${below.md`
    flex-direction: column;
  `};
`;

export const LivePicsWrapper = styled(Wrapper)`
	height: auto;
	width: 40%;
	${below.md`
    width: 100%;
    text-align: center;
  `};
	${below.sm`
    margin-bottom: 20px;
  `};

	&.viewed {
		animation: 0.7s ${fadeInUpAnimation} ease-in-out 0.7s both;
	}
`;

export const LiveImg = styled.img`
	position: absolute;
	bottom: calc(50% - 125px);
	left: 0;
	opacity: 0;

	&#blueBg {
		${below.sm`
      height: 210px;
      width: 300px;
      left: calc(50% - 150px);
    `};
	}
	&#blueNeedle {
		bottom: calc(50% - 12px);
		left: 60px;
		${below.sm`
      left: calc(50% - 98px);
      bottom: calc(50% - 30px);
      width: 100px;
    `};
	}
	&#blue30 {
		bottom: calc(50% - 45px);
		left: 160px;
		${below.sm`
      width: 30px;
      bottom: calc(50% - 60px);
      left: calc(50% - 12px);
    `};
	}
	&#orangeBg {
		${below.sm`
      height: 210px;
      width: 300px;
      left: calc(50% - 150px);
    `};
	}
	&#orangeNeedle {
		bottom: calc(50% - 12px);
		left: 172px;
		${below.sm`
      left: calc(50% - 6px);
      bottom: calc(50% - 30px);
      width: 100px;
    `};
	}
	&#orange4 {
		bottom: calc(50% - 45px);
		left: 170px;
		${below.sm`
      bottom: calc(50% - 63px);
      left: calc(50% - 8px);
    `};
	}
	&#optimalMode {
		height: 370px;
		bottom: calc(50% - 170px);
		${below.md`
      height: 300px;
      left: calc(50% - 110px);
    `};
		${below.sm`
      height: 270px;
    `};
	}
	&#beastMode {
		height: 370px;
		bottom: calc(50% - 170px);
		${below.md`
      height: 300px;
      left: calc(50% - 110px);
    `};
		${below.sm`
      height: 270px;
    `};
	}
`;

export const LiveVid = styled.div`
	position: absolute;
	bottom: calc(50% - 125px);
	left: 0;
	opacity: 0;

	&#bankruptsy,
	&#hulk {
		width: 350px;
		height: 250px;
		border-radius: 8px;
		${below.sm`
      height: 210px;
      width: 300px;
      left: calc(50% - 150px);
    `};
	}

	& #bankvid {
		margin-top: 55px;
	}

	& #hulkvid {
	}
`;

export const LiveVidText = styled.div`
	position: absolute;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 20px;
	justify-content: space-between;
	width: 350px;
	height: 55px;
	background: #131735;
	color: #fff;
	left: 0;
	font-size: 1.2rem;
	${below.sm`
    /* height: 42px; */
    width: 300px;
    left: calc(50% - 150px);
  `};
	& .bigger {
		font-size: 1.7rem;
		font-weight: 600;
	}
	& .smaller {
		font-size: 1rem;
		font-weight: 400;
		opacity: 0.5;
	}

	&#banktext {
		border-radius: 8px 8px 0 0;
		top: 0;
	}
	&#hulktext {
		border-radius: 0 0 8px 8px;
		bottom: 0;
	}
`;

export const LivePics = styled(Wrapper)`
	height: 500px;
	${below.md`
    height: 400px;
    width: 50%;
    margin-bottom: 20px;
  `};
	${below.sm`
    height: 300px;
    width: 100%;
  `};

	&.view {
		& #blueBg,
		& #blueNeedle,
		& #blue30 {
			animation: 1.5s ${fadeInOutLive} linear 1s both;
		}
		& #orangeBg,
		& #orangeNeedle,
		& #orange4 {
			animation: 1.5s ${fadeInOutLive} linear 2.2s both;
		}
		& #bankruptsy {
			animation: 7.1s ${fadeInOutLive} linear 3.6s both;
		}
		& #optimalMode {
			animation: 2.3s ${fadeInOutLive} ease-in-out 10.2s both;
		}
		& #beastMode {
			animation: 2.3s ${fadeInOutLive} ease-in-out 12s both;
		}
		& #hulk {
			animation: 0.5s ${fadeIn} linear 14.2s both;
		}
	}
`;
