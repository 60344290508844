import styled from 'styled-components';

import { Wrapper, below, above } from './utils';
import { Title } from './typography';
import { SectionHeader } from './section';
import { InputField } from './formFields';

export const MerchantsWrapper = styled(Wrapper)`
	flex-direction: row;
	align-items: center;
	margin-top: 20px;
	${below.md`
    flex-direction: ${({ reverse }) => (reverse ? 'column-reverse' : 'column')};
  `};
`;

export const MerchantsHeader = styled(SectionHeader)`
	font-size: 70px;
	${below.md`
    font-size: 40px
  `};
`;

export const MerchantsSubHeader = styled(Title)`
	font-family: 'Conv_EuclidCircularB-Regular';
	${below.lg`
    font-size: 26px;
  `};
	${below.md`
    font-size: 24px;
    margin-bottom: 20px
  `};
	${below.sm`
    font-size: 22px;
  `};
`;

export const MerchantsImg = styled.img`
	height: auto;
	width: 100%;
	max-width: 600px;
	${above.md`
    margin-left: 20px
  `};
`;

export const MerchantsImgContainer = styled(Wrapper)`
	width: auto;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

export const MerchantsListWrapper = styled.ul`
	margin-top: 20px;
	padding: 0;
	columns: 250px ${({ length }) => (length > 30 ? 4 : length > 20 ? 3 : length > 10 ? 2 : 1)};
	${below.sm`
    margin: 10px 10px 0;
  `};
`;

export const MerchantsListItem = styled.li`
	margin-bottom: 40px;
`;

export const MerchantsListHeader = styled.p`
	font-family: 'Conv_EuclidCircularB-Bold';
	margin: 0 0 10px;
`;

export const MerchantsListBody = styled.p`
	margin: 6px 0;
`;

export const MerchantsSearchWrapper = styled.div`
	position: relative;

	img {
		position: absolute;
		left: 10px;
		top: 9px;
		width: 22px;
	}
`;

export const MerchantsSearchInput = styled(InputField)`
	padding-left: 45px;
`;
