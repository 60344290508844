import styled from 'styled-components';

import { Wrapper } from './utils';
import { Body } from './typography';

export const MediaBlock = styled(Wrapper)`
	overflow: hidden;
	background: ${({ theme }) => theme.secondaryColor};
	border-radius: 20px;
	height: 330px;
	width: 30%;
	min-width: 280px;
	margin: 30px 15px;
`;

export const MediaPic = styled(Wrapper)`
	background: ${({ theme }) => theme.grayScaleColor};
`;

export const MediaImg = styled.img`
	height: 65px;
`;

export const MediaText = styled(Wrapper)`
	padding: 20px 30px;
`;

export const MediaMore = styled(Body)`
	color: ${({ theme }) => theme.accentColor};
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	&:hover {
		color: ${({ theme }) => theme.accentColor};
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
	}
`;

export const MediaContainer = styled(Wrapper)`
	margin: 40px 0;
	height: auto;
`;
