import styled from "styled-components";

import { Wrapper, below } from "./utils";
import { Title, Body, Headline, Header } from "./typography";

export const TeamHeadline = styled(Headline)`
	max-width: 550px;
`;

export const TeamTitle = styled(Title)`
	margin: 60px 0 60px;
	font-size: 32px;

	${below.md`
  font-size: 30px;
  `};
`;

export const Member = styled(Wrapper)`
	flex: ${({ section }) =>
		section === `Board of Directors` ? `1 0 33%` : `1 0 24%`};
	height: auto;
	margin: 50px 0;
	${below.md`
      flex: 1 0 33%;
    `};
	${below.sm`
      flex: 1 0 50%;
    `};
`;

export const TeamImg = styled.img`
	margin: 0;
	position: absolute;
	left: calc(50% - 44px);
	top: -60px;
	height: 98px;
	width: 98px;
`;

export const TeamImgCircle = styled.div`
	margin: 0;
	position: absolute;
	left: calc(50% - 44px);
	background: rgba(0, 0, 0, 0.5);
	top: -60px;
	height: 98px;
	width: 98px;
	border-radius: 50%;
`;

export const LinkedIn = styled.img`
	margin: 4px 0;
	width: 14px;
	opacity: 0.8;
	&:hover {
		width: 16px;
		opacity: 1;
		transition: all 0.2s ease-in-out;
	}
`;

export const TeamMedia = styled.a`
	margin: 0;
	padding: 3px 7px;
	background: #1a1e43;
	border: 2px solid #050824;
	line-height: 0;
	border-radius: 50%;
	position: absolute;
	left: calc(50% - 7px);
	top: 10px;
	margin-left: 32px;
`;

export const MemberText = styled(Wrapper)`
	width: 90%;
	margin-top: 55px;
`;

export const MemberName = styled(Headline)`
	margin: 0 0 10px;
	font-family: "Conv_EuclidCircularB-Bold";
	font-size: 23px;
	${below.sm`
    font-size: 20px;
  `};
`;

export const MemberTitle = styled(Headline)`
	opacity: 0.9;
	${below.sm`
    margin-top: 8px;
  `};
`;

export const MemberEducation = styled(Body)`
	opacity: 0.6;
	margin-top: 5px;
	${below.sm`
    margin-top: 8px;
    font-size: 14px;
  `};
`;

export const EmailHeader = styled(Header)`
	margin-bottom: 15px;
`;
