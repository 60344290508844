import React from 'react';
import styled from 'styled-components';

import { KeyboardArrowDown } from '@styled-icons/material';
import { HeadlineHome } from './home';
import { Body, Headline, Title } from './typography';
import { Wrapper, below, above } from './utils';

export const HeadlineFaq = styled(HeadlineHome)`
	font-size: 16px;
	font-family: 'Conv_EuclidCircularB-Bold';
`;

export const BodyFaq = styled(Body)`
	font-size: 14px;
	margin: 0;
	color: rgba(256, 256, 256, 0.85);
`;

export const FaqWrapper = styled(Wrapper)`
	height: auto;
	width: 45%;
	${below.md`
    width: 100%;
  `};

	&:nth-last-of-type(1) {
		& hr {
			display: none;
		}
	}

	&:nth-last-of-type(2) {
		& hr {
			${above.md`
        display: none;
      `};
		}
	}
`;

export const FaqContainer = styled(Wrapper)`
	height: auto;
	margin: 20px auto 60px;
`;

export const FaqHr = styled.hr`
	width: 100%;
	border: none;
	border-top: 1px solid ${({ theme }) => theme.grayScaleVeryFadedColor};
	margin: 20px 0;
`;

export const FAQContainer = styled(Wrapper)`
	width: 90%;
	margin: 0 auto 70px;

	${below.md`
    width: 100%;
  `};
`;

export const FAQRowBG = styled.div`
	border-radius: 10px;
	background: ${({ theme }) => theme.secondaryColor};
	padding: 2px 15px 12px;
	margin: 5px 0;
`;

export const FAQTop = styled(({ hasBG, ...rest }) => <Wrapper {...rest} />)`
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	border-bottom: ${({ theme, hasBG }) => (hasBG ? null : `1px solid ${theme.grayScaleVeryFadedColor}`)};
	cursor: pointer;
	margin-top: 10px;
`;

export const FAQTable = styled(Wrapper)`
	border-radius: 10px;
	overflow: hidden;
`;

export const FAQRow = styled(Wrapper)`
	flex-direction: row;
	justify-content: space-between;
	background: ${({ theme }) => theme.secondaryColor};
	padding: 0 10px;

	&:nth-of-type(2n) {
		background: ${({ theme }) => theme.secondaryFadedColor};
	}

	& p {
		margin: 10px 0;
		padding: 0 10px;
	}
`;

export const FAQHeader = styled(Title)`
	margin-bottom: 0;
	margin-top: 70px;

	&.true {
		margin-top: 10px;
	}
`;

export const FAQTitle = styled(Headline)`
	font-weight: bold;
	margin-left: 5px;
	font-size: 1.1em;
`;

export const FAQBody = styled(Body)`
	margin: 20px auto 15px;
	${below.md`
    width: 90%;
    margin: 15px auto;
  `};

	& ul {
		color: ${({ theme }) => theme.grayScaleColor};
		list-style-type: disc;
		& li {
			margin: 10px 0;
		}
	}
`;

export const FAQArrow = styled(KeyboardArrowDown)`
	width: 25px;
	margin-right: 5px;
	color: ${({ theme }) => theme.accentColor};
	transform: ${({ toggle }) => (toggle === 'EXPANDED' ? 'rotate(180deg)' : 'rotate(0)')};

	&.Faq {
		transform: rotate(-90deg);
		width: 20px;
		margin-bottom: 2px;
		color: inherit;
	}
`;

export const FaqReadMore = styled(Body)`
	cursor: pointer;
	margin: 0 auto 20px;

	&:hover {
		color: ${({ theme }) => theme.accentColor};
	}
	${below.md`
    width: 90%;
    margin-bottom: 60px;
  `};
`;
