import { createGlobalStyle } from 'styled-components';
import { below } from './elements/utils';

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    width: 100%;
    font-size: 16px;
    font-family: 'Conv_EuclidCircularB-Regular';
    line-height: 1.5;
  }

  body {
    padding: 0;
    margin: 0;
    background: ${({ theme }) => theme.primaryColor};
    color: ${({ theme }) => theme.grayScaleColor};
    overflow-x: hidden; 
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.accentSecondaryColor};
    background-color: transparent;
  }
  a:active,
  a:hover {
    outline: 0;
  }

  ul, ol {
    margin: 0 auto;
    list-style-type: none;
  }

  * {
    box-sizing: border-box;
  }

  blockquote,
  q {
    quotes: none;
  }

  b,
  strong {
    font-weight: bold;
    font-family: 'Conv_EuclidCircularB-Bold';
  }

  i,
  em,
  dfn {
    font-style: italic;
  }

  /* Styling for Fullpage Side Dot Nav */
  #fp-nav.fp-right {
    z-index: 5;
    ${below.md`
      right: 0 !important;
    `};

    & ul li {
      margin-right: 4px !important;
    }

    & ul li a span {
      background: transparent !important;
      box-shadow: inset 0 0 0 2px ${({ theme }) => theme.accentFadedColor} !important;
      overflow: hidden !important;
    
      &::after {
        content: '' !important;
        position: absolute !important;
        width: 100% !important;
        height: 0 !important;
        border-radius: 50% !important;
        left: 0 !important;
        bottom: 0 !important;
        background-color: ${({ theme }) => theme.accentColor} !important;
        box-shadow: 0 0 1px ${({ theme }) => theme.accentColor} !important;
        transition: height 0.4s !important;   
      }
    }

    & ul li a.active span, 
    & ul li:hover a.active span {
      background: transparent !important;
      box-shadow: inset 1px 2px 3px 4px ${({ theme }) => theme.accentColor} !important;
        
      &::after {
        height: 100% !important;
      }
    }

    /* tooltip */
    & ul li .fp-tooltip {
      color: ${({ theme }) => theme.grayScaleColor} !important;
      background: ${({ theme }) => theme.secondaryColor} !important;
      padding: 1px 8px;
      border-radius: 3px;

      ${below.md`
        display: none !important;
      `};
    }
  }
`;
