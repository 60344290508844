import React, { useState, useContext } from 'react';
import { ModalProvider } from 'styled-react-modal';
import { useLocation } from '@reach/router';

import { ThemeContext } from '../../providers/ThemeProvider';
import navburger from '../../images/header/nav-burger.png';
import navopen from '../../images/header/nav-open.svg';
import logo from '../../images/home/envel-logo.png';
import logoLight from '../../images/home/envel-logo-light.png';
import ModalSignUp from './ModalSignUp';
// import Toggle from './ThemeToggle/ThemeToggle';
import { useWindowSize } from '../../utils/general';
import {
	NavContainer,
	Navbar,
	Nav,
	Navs,
	NavBrand,
	NavWrapper,
	Burger,
	SpecialModalBackground,
	NavBackground,
	NavImg,
	NavButton,
	Wrapper,
	NavDropItem,
	NavDropdownCom,
	NavLogoLink,
	NavDropItemExternal,
	NavLogoButton,
	NavExternal,
	NavDropdownWrapper,
	ComDownArrowIcon,
} from '../../styles';

const Header = ({ homeRef }) => {
	const [drop1, setDrop1] = useState(false);
	const [drop1Small, setDrop1Small] = useState(false);
	const [drop2, setDrop2] = useState(false);
	const [drop2Small, setDrop2Small] = useState(false);
	const [drop3, setDrop3] = useState(false);
	const [drop3Small, setDrop3Small] = useState(false);
	const [showNav, setShowNav] = useState(false);
	const location = useLocation();
	const { isDesktop } = useWindowSize();
	const { isDarkMode } = useContext(ThemeContext);

	const linkClick = () => {
		if (showNav) setShowNav(false);
	};

	const homeClick = () => {
		linkClick();
		homeRef?.current?.fullpageApi?.moveTo(1);
	};

	const logoClick = () => {
		if (showNav) setShowNav(false);
		homeRef?.current?.fullpageApi?.moveTo(1);
	};

	const comDropClasses = dropdown => {
		if (dropdown === 1 && (location?.pathname.startsWith('/investments/') || location?.pathname.startsWith('/blog/')))
			return 'communityDropButton highlight';

		if (
			dropdown === 2 &&
			(location?.pathname.startsWith('/jobs/') ||
				location?.pathname.startsWith('/media/') ||
				location?.pathname.startsWith('/company-updates/'))
		)
			return 'communityDropButton highlight';

		if (dropdown === 3 && location?.pathname.startsWith('/faq/')) return 'communityDropButton highlight';

		return 'communityDropButton';
	};

	return (
		<ModalProvider backgroundComponent={SpecialModalBackground}>
			<NavBackground>
				<NavContainer row primary>
					<NavWrapper row alignCenter justifyBetween>
						<Wrapper row alignCenter>
							<NavBrand to="/" onClick={logoClick}>
								<NavImg src={isDarkMode ? logo : logoLight} alt="logo" />
							</NavBrand>
							{location?.pathname.startsWith('/blog/') && <NavLogoLink to="/blog/">Blog</NavLogoLink>}
							{/* <Toggle /> */}
						</Wrapper>
						<Burger
							onClick={() => setShowNav(!showNav)}
							src={navburger}
							id="close"
							className={showNav}
							alt="nav-burger"
						/>
						<Burger onClick={() => setShowNav(!showNav)} src={navopen} id="open" className={showNav} alt="nav-cross" />
					</NavWrapper>
					<Navbar showNav={showNav} primary>
						<Navs>
							<Nav to="/" onClick={homeClick}>
								Home
							</Nav>
							<Nav to="/investing" onClick={homeClick}>
								Investments
							</Nav>
							{/* <Nav to="/graduates/" onClick={linkClick}>
								Graduates
							</Nav>
							<Nav to="/professionals/" onClick={linkClick}>
								Professionals
							</Nav> */}

							<NavDropdownWrapper onMouseEnter={() => setDrop1(true)} onMouseLeave={() => setDrop1(false)}>
								<NavButton onClick={() => setDrop1Small(!drop1Small)} className={comDropClasses(1)}>
									Community
									<ComDownArrowIcon drop1Small={drop1Small} />
								</NavButton>
								<NavDropdownCom drop1={drop1} drop1Small={drop1Small} onClick={linkClick}>
									<NavDropItem className="top" to="/blog/">
										Blog
									</NavDropItem>
									<NavDropItemExternal
										href="https://discord.com/invite/Brw8d9FjhT"
										target="_blank"
										rel="noopener noreferrer"
									>
										Join us on Discord
									</NavDropItemExternal>
									{/* <NavDropItem className="bottom" to="/investments/">
										Investments
									</NavDropItem> */}
								</NavDropdownCom>
							</NavDropdownWrapper>

							<NavDropdownWrapper onMouseEnter={() => setDrop2(true)} onMouseLeave={() => setDrop2(false)}>
								<NavButton onClick={() => setDrop2Small(!drop2Small)} className={comDropClasses(2)}>
									Company
									<ComDownArrowIcon drop1Small={drop2Small} />
								</NavButton>
								<NavDropdownCom drop1={drop2} drop1Small={drop2Small} onClick={linkClick}>
									<NavDropItem className="top" to="/jobs/">
										Careers
									</NavDropItem>
									<NavDropItem to="/media/">News</NavDropItem>
									{/* <NavDropItem className="bottom" to="/company-updates/">
										Company Updates
									</NavDropItem> */}
								</NavDropdownCom>
							</NavDropdownWrapper>

							<NavDropdownWrapper onMouseEnter={() => setDrop3(true)} onMouseLeave={() => setDrop3(false)}>
								<NavButton onClick={() => setDrop3Small(!drop3Small)} className={comDropClasses(3)}>
									Help
									<ComDownArrowIcon drop1Small={drop3Small} />
								</NavButton>
								<NavDropdownCom drop1={drop3} drop1Small={drop3Small} onClick={linkClick}>
									<NavDropItem className="top" to="/faq/">
										FAQ
									</NavDropItem>
									<NavDropItemExternal
										className="bottom"
										href="https://support.envel.ai/"
										target="_blank"
										rel="noopener noreferrer"
									>
										Support
									</NavDropItemExternal>
								</NavDropdownCom>
							</NavDropdownWrapper>

							{/* <NavExternal
								href="https://support.envel.ai/"
								target="_blank"
								rel="noopener noreferrer"
								onClick={linkClick}
							>
								Help
							</NavExternal> */}
							<Nav id="calcNav" to="/financial-fitness-calculator/" onClick={linkClick}>
								Financial Fitness
							</Nav>
							<ModalSignUp setShowNav={setShowNav} showNav={showNav} />
						</Navs>
					</Navbar>
				</NavContainer>
			</NavBackground>
		</ModalProvider>
	);
};

export default Header;
