import styled from 'styled-components';

import { Body, Header, Headline, Title } from './typography';
import { Wrapper, below } from './utils';

export const ReferralWrapper = styled(Wrapper)`
	padding: 30px;
	margin: 0 0 30px;
	border-radius: 25px;
	height: 100%;
	background: ${({ theme }) => theme.secondaryColor};
	${below.sm`
    flex-direction: column !important;
  `};
`;

export const ReferralContainer = styled(Wrapper)`
	${below.sm`
    flex-direction: column !important;
  `};
`;

export const ReferralQRContainer = styled(Wrapper)`
	margin-top: 30px;
`;

export const ReferralHeader = styled(Header)`
	margin-bottom: 20px;
`;

export const ReferralHeadline = styled(Headline)`
	margin: 0 0 60px;
	${below.sm`
    margin: 0 0 40px;
  `};
`;

export const ReferralTitle = styled(Title)`
	margin-bottom: 0px;
`;

export const ReferralOption = styled(Body)`
	font-size: 0.9em;
	opacity: 0.6;
	margin-bottom: 8px;
`;

export const ReferralBody = styled.div`
	width: 50%;
	&.big {
		width: 80%;
	}
	&.left {
		margin-right: 30px;
	}

	${below.sm`
    width: 100%;
    &.top {
      margin-bottom: 30px;
    }
    &.big {
      width: 100%;
    }
  `};
`;

export const ReferralFooter = styled(Body)`
	margin-top: 60px;
	${below.sm`
    margin-top: 30px;
  `};
`;

export const ReferralImage = styled.img`
	max-width: 300px;
	margin: 60px auto 20px;
	${below.sm`
    max-width: 90%;
  `};
`;
