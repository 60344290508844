import { keyframes } from "styled-components";

// ==========================================
// SPLIT BILL SECTION 4
// ==========================================
export const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

// ==========================================
// REWARDS SECTION 5
// ==========================================
export const rotateOut = keyframes`
  0% {
    transform: scale(1) rotate(0);
  }
  100% {
    transform: scale(2) rotate(960deg); opacity: 0;
  }
`;

export const slideUp = keyframes`
  0% {
    transform: translateY(190px);
    z-index: 1000;
    position: relative;
    display: inline-block;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`;

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

// ==========================================
// SLIDER SECTION 6
// ==========================================

export const goalsSlideLeft = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-130px);
  }
`;

export const goalsSlideRight = keyframes`
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  100% {
    transform: translateX(130px);
    opacity: 1;
  }
`;

export const goalsSlideLeftSmall = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-82px);
  }
`;

export const goalsSlideRightSmall = keyframes`
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  100% {
    transform: translateX(82px);
    opacity: 1;
  }
`;

// ============================================
// ENVELOPES HOME PAGE SECTION 1
// ============================================
export const cashSlideLeft = keyframes`
  0% {
    transform: translateX(435px);
  }
  100% {
    transform: translateX(0);
  }
`;

export const emergencySlideLeft = keyframes`
  0% {
    transform: translateX(142px); 
    opacity: 0;
  }
  100% {
    transform: translateX(0); 
    opacity: 1;
  }
`;

export const billsSlideRight = keyframes`
  0% {
    transform: translateX(-152px); 
    opacity: 0;
  }
  100% {
    transform: translateX(0); 
    opacity: 1;
  }
`;

export const vaultSlideRight = keyframes`
  0% {
    transform: translateX(-445px); 
    opacity: 0;
  }
  100% {
    transform: translateX(0); 
    opacity: 1;
  }
`;

export const cashSlideLeftMed = keyframes`
  0% {
    transform: translateX(350px);
  }
  100% {
    transform: translateX(0);
  }
`;

export const textShake = keyframes`
  0% {
    transform: translateY(0);
  }
  33% {
    transform: translateY(5px);
  }
  66% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
`;

// ==========================================
// LIVE SECTION 4
// ==========================================
export const fadeInOutLive = keyframes`
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

// ==========================================
// COINS HOME PAGE SECTION 3
// ==========================================
export const cashSwitch = keyframes`
  0%, 23% {
    transform: scale(1.05);
    z-index: 10;
    top: 0;
  }
  26%, 49% {
    transform: scale(0.92);
    z-index: 5;
    top: 36px;
  }
  52%, 75% {
    transform: scale(0.8);
    z-index: 1;
    top: -71px;
  }
  78%, 100% {
    transform: scale(0.92);
    z-index: 5;
    top: -36px;
  }
`;

export const billsSwitch = keyframes`
  0%, 23% {
    transform: scale(0.92);
    z-index: 5;
    top: -36px;
  }
  26%, 49% {
    transform: scale(1.05);
    z-index: 10;
    top: 0;
  }
  52%, 75% {
    transform: scale(0.92);
    z-index: 5;
    top: 36px;
  }
  78%, 100% {
    transform: scale(0.8);
    z-index: 1;
    top: -71px;
  }
`;

export const vaultSwitch = keyframes`
  0%, 23% {
    transform: scale(0.8);
    z-index: 1;
    top: -71px;
  }
  26%, 49% {
    transform: scale(0.92);
    z-index: 5;
    top: -36px;
  }
  52%, 75% {
    transform: scale(1.05);
    z-index: 10;
    top: 0;
  }
  78%, 100% {
    transform: scale(0.92);
    z-index: 5;
    top: 36px;
  }
`;

export const emergencySwitch = keyframes`
  0%, 23% {
    transform: scale(0.92);
    z-index: 5;
    top: 36px;
  }
  26%, 49% {
    transform: scale(0.8);
    z-index: 1;
    top: -71px;
  }
  52%, 75% {
    transform: scale(0.92);
    z-index: 5;
    top: -36px;
  }
  78%, 100% {
    transform: scale(1.05);
    z-index: 10;
    top: 0;
  }
`;

export const coinsFall = keyframes`
  0% {
    transform: translateY(-150px);
    opacity: 0;
  }
  2.5% {
    transform: translateY(-75px);
    opacity: 1;
  }
  5% {
    transform: translateY(0);
    opacity: 0;
  }
  25% {
    transform: translateY(-150px);
    opacity: 0;
  }
  27.5% {
    transform: translateY(-75px);
    opacity: 1;
  }
  30% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    transform: translateY(-150px);
    opacity: 0;
  }
  52.5% {
    transform: translateY(-75px);
    opacity: 1;
  }
  55% {
    transform: translateY(0);
    opacity: 0;
  }
  75% {
    transform: translateY(-150px);
    opacity: 0;
  }
  77.5% {
    transform: translateY(-75px);
    opacity: 1;
  }
  80% {
    transform: translateY(0);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
`;

export const coinsFallMed = keyframes`
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  2.5% {
    transform: translateY(-50px);
    opacity: 1;
  }
  5% {
    transform: translateY(0);
    opacity: 0;
  }
  25% {
    transform: translateY(-100px);
    opacity: 0;
  }
  27.5% {
    transform: translateY(-50px);
    opacity: 1;
  }
  30% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    transform: translateY(-100px);
    opacity: 0;
  }
  52.5% {
    transform: translateY(-50px);
    opacity: 1;
  }
  55% {
    transform: translateY(0);
    opacity: 0;
  }
  75% {
    transform: translateY(-100px);
    opacity: 0;
  }
  77.5% {
    transform: translateY(-50px);
    opacity: 1;
  }
  80% {
    transform: translateY(0);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
`;

export const bubbleClockwise = keyframes`
  0% {
    transform: rotate(-720deg);
  }
  5% {
    transform: rotate(-360deg);
  }
  25% {
    transform: rotate(-360deg);
  }
  30% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(0);
  }
  55% {
    transform: rotate(360deg);
  }
  75% {
    transform: rotate(360deg);
  }
  80% {
    transform: rotate(720deg);
  }
  100% {
    transform: rotate(720deg);
  }
`;

export const bubbleAntiClockwise = keyframes`
  0% {
    transform: rotate(720deg);
  }
  5% {
    transform: rotate(360deg);
  }
  25% {
    transform: rotate(360deg);
  }
  30% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(0);
  }
  55% {
    transform: rotate(-360deg);
  }
  75% {
    transform: rotate(-360deg);
  }
  80% {
    transform: rotate(-720deg);
  }
  100% {
    transform: rotate(-720deg);
  }
`;

// ==========================================
// BRAIN HOME PAGE SECTION 3
// ==========================================

export const brainAntiClockwise = keyframes`
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(-540deg);
  }
`;

export const brainClockwise = keyframes`
  0% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(540deg);
  }
`;

export const fadeInOutBrain = keyframes`
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  88% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

// ============================================
// CARDS HOME PAGE SECTION 8
// ============================================
export const redSlideLeft = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-85%, 5%) rotate(-20deg); 
  }
`;

export const yellowSlideLeft = keyframes`
  0% {
    transform: translate(0, 0); 
  }
  100% {
    transform: translate(-35%, -5% ) rotate(-10deg); 
  }
`;

export const purpleSlideRight = keyframes`
  0% {
    transform: translate(0, 0); 
  }
  100% {
    transform: translate(35%, -5%) rotate(10deg);
  }
`;

export const blueSlideRight = keyframes`
  0% {
    transform: translate(0, 0); 
  }
  100% {
    transform: translate(85%, 5%) rotate(20deg);
  }
`;

// ==========================================
// Calc Load
// ==========================================

export const loadAntiClockwise = keyframes`
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(-720deg);
  }
`;

export const loadClockwise = keyframes`
  0% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(720deg);
  }
`;

// ==========================================
// Job loader
// ==========================================

export const loaderSpin = keyframes`
  0% {
    transform: rotate(360deg);
  }
  47% {
    transform: rotate(-100deg);
  }
  52% {
    transform: rotate(-100deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// ==========================================
// Chip Animation Landing Page
// ==========================================

export const topBoard = keyframes`
  0% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10%);
  }
  ${`` /* 52% {
    transform: rotate(-100deg);
  } */}
  100% {
    transform: translateY(22.5%);
  }
`;

export const ColTurq = keyframes`
  0% {
    transform: opacity(0), rotate(200deg);
  }
  100% {
    transform: opacity(1);
  }
`;

// ==========================================
// Apple Pay Animation
// ==========================================

export const fadeInOutFadedHalf = keyframes`
  0% {
    opacity: 0.4;
  }
  10% {
    opacity: 1;
    width: 6px;
    height: 6px;
  }
  90% {
    opacity: 1;
    width: 6px;
    height: 6px;
  }
  100% {
    opacity: 0.4;
    width: 5px;
    height: 5px;
  }
`;

export const fadeInOutFadedFull = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.4;
  }
  90% {
    opacity: 0.4;
  }
  100% {
    opacity: 0;
  }
`;
