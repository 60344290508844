import styled from 'styled-components';

import { Wrapper, below } from './utils';

export const ArticleImg = styled.img`
	width: 110px;
	height: 110px;
	margin: 10px 25px 10px 10px;
	${below.xs`
	  margin: 0;
  `};
`;

export const ArticleBody = styled.div`
	letter-spacing: 0.1px;
	margin-bottom: 40px;

	& p {
		opacity: 0.8;
	}

	& .subtext {
		opacity: 0.5;
		font-size: 0.85em;
	}
`;

export const ArticleWrapper = styled(Wrapper)`
	padding: 10px;
	background: #131735;
	border-radius: 20px;

	${below.xs`
    flex-direction: column;
    align-items: start;
  `};
`;
