import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { getColor, below, getFlexJustify, getFlexAlign, Wrapper, above } from './utils';
import { Button, GradientButton } from './buttons';
import { KeyboardArrowDown } from '@styled-icons/material';

export const NavBackground = styled.header`
	width: 100%;
	height: 86px;
	position: fixed;
	z-index: 10;
	background-color: ${({ theme }) => theme.primaryColor};
	touch-action: none;
	${below.lg`
    height: 70px;
  `};
`;

export const NavContainer = styled(
	({
		justifyStart,
		justifyEnd,
		justifyAround,
		justifyBetween,
		justifyEvenly,
		justifyCenter,
		alignStart,
		alignEnd,
		alignBaseline,
		alignCenter,
		alignStretch,
		row,
		color,
		primary,
		secondary,
		grayScale,
		...rest
	}) => <div {...rest} />
)`
	display: flex;
	flex-direction: ${({ row }) => (row ? 'row' : 'column')};
	justify-content: ${props => getFlexJustify(props, 'space-between')};
	align-items: ${props => getFlexAlign(props, 'center')};
	color: #fff;
	height: 86px;
	width: 1140px;
	margin: 0 auto;
	padding: 15px;
	background-color: ${props => getColor(props)};
	touch-action: none;
	${below.lg`
    max-width: 960px;
  `};
	${below.lg`
    max-width: 720px;
    height: 70px;
    flex-direction: column;
    align-items: flex-start;
  `};
	${below.sm`
    max-width: 100%;
  `};
`;

export const Burger = styled.img`
	display: none;
	height: 30px;
	width: 30px;
	cursor: pointer;
	touch-action: none;
	transition: 0.1s all linear;
	${below.lg`
    display: block;
  `};
	&#close {
		&.true {
			opacity: 0;
		}
	}

	&#open {
		position: absolute;
		z-index: 20;
		right: 0;
		opacity: 0;
		&.true {
			opacity: 1;
		}
	}
`;

export const NavImg = styled.img`
	height: 30px;
	${below.xxs`
    height: 25px;
  `};
	${below.xxxs`
    height: 22px;
  `};
`;

export const NavBrand = styled(Link)`
	cursor: pointer;
`;

export const NavWrapper = styled(Wrapper)`
	${above.lg`
    width: auto;
  `};
`;

export const Navbar = styled(
	({
		justifyStart,
		justifyEnd,
		justifyAround,
		justifyBetween,
		justifyEvenly,
		justifyCenter,
		alignStart,
		alignEnd,
		alignBaseline,
		alignCenter,
		alignStretch,
		row,
		color,
		primary,
		secondary,
		grayScale,
		width,
		showNav,
		...rest
	}) => <div {...rest} />
)`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	touch-action: none;
	width: 100%;
	${below.lg`
    flex-direction: ${({ row }) => (row ? 'row' : 'column')};
    justify-content: ${props => getFlexJustify(props)};
    align-items: ${props => getFlexAlign(props)};
    position: fixed;
    padding: 0 100px;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    display: ${({ showNav }) => (showNav ? 'flex' : 'none')};
    background-color: ${props => getColor(props)};
  `};
	${below.sm`
    width: 100%;
    padding: 0 15px;
    left: 0;
    right: 0;
  `};
`;

export const Navs = styled(
	({
		justifyStart,
		justifyEnd,
		justifyAround,
		justifyBetween,
		justifyEvenly,
		justifyCenter,
		alignStart,
		alignEnd,
		alignBaseline,
		alignCenter,
		alignStretch,
		row,
		...rest
	}) => <div {...rest} />
)`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	touch-action: none;
	height: 100%;
	${below.lg`
    flex-direction: ${({ row }) => (row ? 'row' : 'column')};
    justify-content: ${props => getFlexJustify(props)};
    align-items: ${props => getFlexAlign(props)};
    margin: 0;
  `};
`;

const activeClassName = 'active';
export const Nav = styled(Link).attrs({ activeClassName })`
	font-size: 16px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	margin-right: 25px;
	color: ${({ theme }) => theme.grayScaleFadedColor};
	transition: all 0.2s ease-in-out;
	&#calcNav {
		display: block;
		-webkit-mask-image: ${({ theme }) =>
			`-webkit-linear-gradient(0deg, ${theme.accentColor}, ${theme.accentSecondaryColor})`};
		mask-image:${({ theme }) => `-webkit-linear-gradient(0deg, ${theme.accentColor}, ${theme.accentSecondaryColor})`};
		background: ${({ theme }) => `-webkit-linear-gradient(0deg, ${theme.accentColor}, ${theme.accentSecondaryColor})`};
		-webkit-background-clip: text;
		background-clip: text;
		-webkit-text-fill-color: transparent;
		text-align: center;
		padding-top: 3px;
		${below.lg`
      margin: 15px;
      margin-bottom: 30px;
    `};

		&.${activeClassName}, &:hover {
			-webkit-mask-image: ${({ theme }) =>
				`-webkit-linear-gradient(180deg, ${theme.accentColor}, ${theme.accentSecondaryColor})`};
			mask-image: ${({ theme }) => `-webkit-linear-gradient(180deg, ${theme.accentColor}, ${theme.accentSecondaryColor})`};
			background: ${({ theme }) => `-webkit-linear-gradient(180deg, ${theme.accentColor}, ${theme.accentSecondaryColor})`};
			-webkit-background-clip: text;
			background-clip: text;
			-webkit-text-fill-color: transparent;
			text-align: center;
		}
	}
	${below.lg`
    margin: 15px;
  `};
	${below.sm`
    margin: 15px;
  `};
	&.${activeClassName}, &:hover {
		color: ${({ theme }) => theme.grayScaleColor};
		transition: all 0.2s ease-in-out;
	}
	/* ${above.lg`
    &.${activeClassName}::before, &:hover::before {
      background: ${({ theme }) => theme.accentSecondaryColor};
      height: 7px;
      width: 7px;
      content: '';
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 0;
      border-radius: 90px;
      margin: 0 auto;
    }
  `}; */
`;

export const NavExternal = styled.a`
	font-size: 16px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	margin-right: 25px;
	color: ${({ theme }) => theme.grayScaleFadedColor};
	transition: all 0.2s ease-in-out;
	${below.lg`
    margin: 25px;
    &:nth-last-of-type() {
      margin-bottom: 30px;
    }
  `};
	${below.sm`
    margin: 15px;
    &:nth-last-of-type() {
      margin-bottom: 25px;
    }
  `};
	&:hover {
		color: ${({ theme }) => theme.grayScaleColor};
		transition: all 0.2s ease-in-out;
	}
	/* ${above.lg`
    &:hover::before {
      background: ${({ theme }) => theme.accentSecondaryColor};
      height: 7px;
      width: 7px;
      content: '';
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 0;
      border-radius: 90px;
      margin: 0 auto;
    }
  `}; */
`;

export const NavDropdown = styled.div`
	display: none;
	background: ${({ theme }) => theme.secondaryColor};
	position: absolute;
	top: 40px;
	left: 0;
	/* right: 0; */
	border-radius: 7px;
	margin-left: 25px;
	padding: 0;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.3);
	${below.lg`
    margin-left: 0;
    top: 0;
    display: none;
    position: relative;
  `};
`;

export const NavDropdownWrapper = styled(Wrapper)`
	width: auto;
	height: auto;
`;

export const NavDropdownCom = styled(NavDropdown)`
	display: ${({ drop1 }) => (drop1 ? 'block' : 'none')};
	top: 33px;
	white-space: nowrap !important;
	margin-left: -5px;
	margin-right: 5px;
	z-index: 3;
	${below.lg`
    display: ${({ drop1Small }) => (drop1Small ? 'block' : 'none')};
    margin-left: 0;
    margin-right: 0;
    background: none;
    box-shadow: none;
  `};
`;

export const NavDropdownHar = styled(NavDropdown)`
	display: ${({ drop2 }) => (drop2 ? 'block' : 'none')};
	${below.lg`
    display: ${({ drop2Small }) => (drop2Small ? 'block' : 'none')};
  `};
`;

export const NavDropItem = styled(Link)`
	border-radius: 7px;
	display: block;
	padding: 7px 15px;
	cursor: pointer;
	color: ${({ theme }) => theme.grayScaleFadedColor};

	&:hover {
		color: ${({ theme }) => theme.grayScaleColor};
		transition: all 0.2s ease-in-out;
	}

	&.top {
		padding-top: 15px;
	}
	&.bottom {
		padding-bottom: 15px;
	}

	${below.lg`
    text-align: center;
    &.top {
      padding-top: 0px;
    }
  `};
`;

export const NavDropItemExternal = styled.a`
	border-radius: 7px;
	display: block;
	padding: 7px 15px;
	cursor: pointer;
	color: ${({ theme }) => theme.grayScaleFadedColor};

	&:hover {
		color: ${({ theme }) => theme.grayScaleColor};
		transition: all 0.2s ease-in-out;
	}

	&.top {
		padding-top: 15px;
	}
	&.bottom {
		padding-bottom: 15px;
	}

	${below.lg`
    text-align: center;
    &.top {
      padding-top: 0px;
    }
  `};
`;

export const NavButton = styled(Button)`
	background: ${({ theme }) => theme.primaryColor};
	padding: 8px 15px;
	border-radius: 7px;
	${below.lg`
  width: 100%;
  `};

	&.join {
		color: ${({ theme }) => theme.accentColor};
		&:hover {
			color: ${({ theme }) => theme.grayScaleColor};
			background: ${({ theme }) => theme.accentColor};
		}
	}

	&#harvNav {
		padding: 10px 15px;
		margin-left: 25px;
		width: 130px;
		position: relative;
		background: ${({ theme }) => theme.accentColor};
		background-image: linear-gradient(to right, rgb(118, 50, 251) 0%, rgb(52, 96, 253) 51%, rgb(118, 50, 251) 100%);
		background-size: 200% auto;
		transition: all 0.2s ease-in-out;
		&:hover {
			background-position: right center;
			outline: none;
			transition: all 0.2s ease-in-out;
		}
		${below.lg`
      margin: 20px 0 0;
      width: auto;
    `};
	}

	&.communityDropButton {
		padding: 0;
		margin-right: 25px;
		height: 33px;
		color: ${({ highlight, theme }) => (highlight ? theme.grayScaleColor : theme.grayScaleFadedColor)};
		${below.lg`
      margin: 15px 0;
    `};

		&.highlight,
		&:hover {
			color: ${({ theme }) => theme.grayScaleColor};
			transition: all 0.2s ease-in-out;
			& img {
				opacity: 1;
			}
		}
	}
`;

export const NavButtonBack = styled.div`
	/* background: linear-gradient(
		to right,
		rgb(118, 50, 251) 0%,
		rgb(52, 96, 253) 100%
	); */
	background: ${({ theme }) => theme.accentColor};
	border-radius: 7px;
	padding: 1px;

	/* &:hover {
		background: linear-gradient(
			to right,
			rgb(52, 96, 253) 0%,
			rgb(118, 50, 251) 100%
		);
		transition: all 0.2s ease-in-out;
	} */
`;

export const ComDownArrow = styled.img`
	padding-bottom: 2px;
	opacity: 0.6;
	${below.lg`
    transform: ${({ drop1Small }) => (drop1Small ? 'rotate(180deg) translateY(3px)' : 'none')};
  `};
`;

export const ComDownArrowIcon = styled(KeyboardArrowDown)`
	width: 16px;
	${below.lg`
    transform: ${({ drop1Small }) => (drop1Small ? 'rotate(180deg) translateY(3px)' : 'none')};
  `};
`;

export const NavLogoButton = styled(GradientButton)`
	margin: 0 5px 5px 13px;
	padding: 7px 10px;
	white-space: nowrap;
	font-size: 0.9em;
	${below.xs`
    padding: 5px 7px;
    font-size: 0.9em;
  `};
	${below.xxxs`
    margin-left: 8px;
    padding: 3px 5px;
    font-size: 0.8em;
  `};
	background-image: linear-gradient(
		to right,
		${({ theme }) => theme.accentColor} 0%,
		${({ theme }) => theme.accentSecondaryColor} 60%
	);
`;

export const NavLogoLink = styled(Link)`
	cursor: pointer;
	font-size: 1.3em;
	color: ${({ theme }) => theme.grayScaleColor};
	border-left: 1px solid ${({ theme }) => theme.grayScaleColor};
	padding-left: 10px;
	margin-left: 10px;

	&:hover {
		color: ${({ theme }) => theme.accentColor};
	}
`;
