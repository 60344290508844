// I need the dark mode colors to be bg: 221134 and secondary: 382C48

export const COLORS = {
	primaryColor: {
		light: '#FFFFFF',
		// dark: '#141519',
		dark: '#221134',
	},
	secondaryColor: {
		light: '#eee',
		// dark: '#303239',
		dark: '#382C48',
	},
	secondaryFadedColor: {
		light: '#eeeeee66',
		// dark: '#30323966',
		dark: '#382C4866',
	},
	accentColor: {
		// light: '#0399FF',
		// dark: '#0399FF',
		light: '#8867E2',
		dark: '#8867E2',
	},
	accentFadedColor: {
		// light: '#0399FF40',
		// dark: '#0399FF40',
		light: '#8867E240',
		dark: '#8867E240',
	},
	accentSecondaryColor: {
		light: '#00FFCE',
		dark: '#00FFCE',
	},
	// accentPurpleColor: {
	// 	light: '#6955f3',
	// 	dark: '#6955f3',
	// },
	grayScaleColor: {
		light: '#000000',
		dark: '#ffffff',
	},
	grayScaleFadedColor: {
		light: '#00000077',
		dark: '#FFFFFFa0',
	},
	grayScaleVeryFadedColor: {
		light: '#00000022',
		dark: '#FFFFFF22',
	},
	errorColor: {
		light: '#e7383b',
		dark: '#e7383b',
	},
};

export const COLOR_MODE_KEY = 'color-mode';
export const INITIAL_COLOR_MODE_CSS_PROP = '--initial-color-mode';

export const theme = Object.fromEntries(Object.keys(COLORS).map(c => [c, `var(--color-${c})`]));
