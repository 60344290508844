import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';

import { loadAntiClockwise, loadClockwise } from './keyframes';
import { Wrapper, below } from './utils';
import { Header, Body, Title } from './typography';
import { GradientButton } from './buttons';
import { Tooltip } from './formFields';

const fadeInAnimation = keyframes`${fadeIn}`;

// ==============================================
//  Calc Inputs
// ==============================================
export const NativeSlider = withStyles({
	root: {
		height: 4,
	},
	thumb: {
		height: 21,
		width: 21,
		marginTop: -8,
		marginLeft: -12,
		boxShadow: '0px 0px 4px 2px rgba(265, 265, 265, 0.2)',
		'&:focus, &:hover, &$active': {
			boxShadow: '0px 0px 4px 2px rgba(265, 265, 265, 0.2)',
		},
	},
	active: {},
	valueLabel: {
		left: 'calc(-50% + 5px)',
	},
	track: {
		height: 4,
		borderRadius: 2,
	},
	rail: {
		height: 4,
		borderRadius: 2,
	},
})(Slider);

const StyledSlider = styled(NativeSlider)`
	&.MuiSlider-root {
		width: 250px !important;
		margin: 0 25px 0 30px !important;
		${below.xs`
      width: 230px !important;
      margin: 0 15px 0 10px !important;
    `};
	}
`;

export const SubSlider = styled(StyledSlider)`
	&.MuiSlider-root {
		color: #e7383b !important;
	}
`;

export const UtilSlider = styled(StyledSlider)`
	&.MuiSlider-root {
		color: #ffa800 !important;
	}
`;

export const RentSlider = styled(StyledSlider)`
	&.MuiSlider-root {
		color: #1ac9e1 !important;
	}
`;

export const IncomeSlider = styled(StyledSlider)`
	&.MuiSlider-root {
		color: #4b43f1 !important;
	}
`;

export const PeriodSlider = styled(StyledSlider)`
	&.MuiSlider-root {
		color: #5e80ff !important;
	}
`;

export const CalcRangeImg = styled.img`
	height: 40px;
	margin-left: 10px;

	&.subs {
		height: 30px;
		margin-left: 15px;
	}
`;

export const CalcSlider = styled(Wrapper)`
	flex-direction: row;
	margin-bottom: 10px;
	width: auto;
	height: auto;
	${below.sm`
    flex-direction: column;
  `};

	&.coverLabel {
		${below.sm`
      align-items: flex-start;
    `};
	}
`;

export const CalcLabel = styled(Wrapper)`
	flex-direction: row;
	align-items: center;
	width: 200px;
	height: auto;
	line-height: 1 !important;
	${below.sm`
    padding-left: 5px;
  `};

	&.safetyBlanket {
		width: 75px;
		${below.sm`
      width: 200px;
    `};
		${below.xs`
      margin-left: 10px;
    `};
	}

	&.coverLabel {
		${below.sm`
      margin-bottom: 15px;      
    `};
	}
`;

export const CalcRange = styled(Wrapper)`
	flex-direction: row;
	align-items: center;
	height: auto;
	width: 360px;

	${below.xs`
    width: 340px;
  `};

	&.safetyBlanket {
		width: 395px;
		${below.xs`
      width: 340px;
    `};
	}
`;

export const CalcHeader = styled(Header)`
	margin-bottom: 10px;
	font-size: 37px;
	${below.xs`
    font-size: 29px;
    line-height: 1.1;
  `};
	${below.xxs`
    font-size: 25px;
  `};
`;

export const CalcHeadline = styled(Body)`
	margin: 0 15px 15px;
	font-size: 18px;
	${below.sm`
    margin-top: 15px;
  `};
	${below.xxs`
    margin-top: 0;
  `};

	&.safetyBlanket {
		margin: 35px 0 20px !important;
	}
`;

export const NetText = styled(Wrapper)`
	${below.sm`
    margin-bottom: 20px;
  `};
`;

export const CalcBody = styled(Body)`
	line-height: 1;
	${below.sm`
    margin-bottom: 0;
  `};
`;

export const CalcButton = styled(GradientButton)`
	margin-top: 40px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
	/* Hide checkbox visually but remain accessible to screen readers.
  Source: https://polished.js.org/docs/#hidevisually */
	border: 0;
	clip: rect(0 0 0 0);
	/* clippath: inset(50%); */
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;

export const StyledCheckbox = styled.div`
	display: inline-block;
	width: 20px;
	height: 20px;
	background: ${({ checked, theme }) => (checked ? theme.accentColor : theme.secondaryColor)};
	border-radius: 3px;
	transition: all 0.1s;

	${HiddenCheckbox}:focus + & {
		box-shadow: 0 0 0 1px ${({ theme }) => theme.accentColor};
	}

	img {
		visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
	}
`;

export const CheckboxContainer = styled.div`
	display: inline-block;
	vertical-align: middle;
	margin: 0 7px;
`;

export const CheckboxLabel = styled.label`
	color: ${({ theme }) => theme.grayScaleColor};
	margin: 0 10px 0 15px;
	line-height: 1;
	${below.md`
    margin: 0 5px 0 8px;
  `};

	&:nth-of-type(1) {
		margin-left: 20px;
		${below.md`
      margin-left: 10px;
    `};
	}
`;

export const CalcTooltip = styled(Tooltip)`
	margin-left: 10px;
	font-size: 12px;
	padding: 2px 6px;
	background: ${({ theme }) => theme.grayScaleFadedColor};
	color: ${({ theme }) => theme.primaryColor};

	&::before,
	&::after {
		color: ${({ theme }) => theme.grayScaleColor};
	}
`;

// ==============================================
// Calc Load
// ===============================================
export const LoadContainer = styled.div`
	position: absolute;
	animation: 1s ${fadeInAnimation} ease-in-out both;
	top: 35vh;
	width: 100%;
	${below.md`
    top: 20vh;
  `};
`;

export const LoadAnimWrapper = styled(Wrapper)`
	width: 100%;
	height: 70vh;
	align-items: center;
	justify-content: center;
	${below.md`
    height: 55vh;
  `};
`;

export const LoadImg = styled.img`
	position: absolute;
	&:nth-of-type(1) {
		top: -164px;
		left: calc(50% - 39px);
		${below.md`
      top: -64px;
    `};
	}
	&:nth-of-type(2) {
		top: -201.5px;
		left: calc(50% - 71.5px);
		${below.md`
      top: -101.5px;
    `};
		animation: 1.5s ${loadAntiClockwise} ease-in-out 1.1s both infinite;
	}
	&:nth-of-type(3) {
		top: -230.5px;
		left: calc(50% - 100px);
		${below.md`
      top: -130.5px;
    `};
		animation: 1.5s ${loadClockwise} ease-in-out 1.1s both infinite;
	}
`;

// ==============================================
// Calc Outputs
// ===============================================

export const CalcNet = styled(Header)`
	margin: 0 0 20px;
	font-weight: 400 !important;
	${below.xs`
    margin-bottom: 0;
  `};
`;

export const DailyNet = styled(Title)`
	${below.xs`
    font-size: 20px;
  `};
`;

export const CalcOutputsCont = styled.div`
	& .react-share__ShareButton {
		height: auto;
		outline: none;
	}
	animation: 1s ${fadeInAnimation} ease-in-out both;
`;

export const SocialImg = styled.img`
	margin: 3px 5px;
	height: 29px;
	width: 29px;
`;

export const SocialContainer = styled.div`
	width: 360px;
	${below.md`
    width: 340px;
  `};
`;

export const ShareContainer = styled(Wrapper)`
	margin-top: 20px;
`;

export const CalcCircles = styled.div`
	width: 150px;
	height: 150px;
	position: relative;
`;

export const DailyLimits = styled(Wrapper)`
	width: auto;
	margin: 40px auto;
`;

export const Limits = styled.div`
	& strong {
		letter-spacing: 1.1px !important;
	}
	margin-left: 30px;
	${below.sm`
    margin-left: 8px;
    width: 170px;
  `};
`;

export const CalcCircle = styled.div`
	position: absolute;

	&:nth-of-type(1) {
		width: 150px;
		top: 0;
		left: 0;
	}
	&:nth-of-type(2) {
		width: 120px;
		top: 15px;
		left: 15px;
	}
	&:nth-of-type(3) {
		width: 90px;
		top: 30px;
		left: 30px;
	}
	&:nth-of-type(4) {
		width: 60px;
		top: 45px;
		left: 45px;
	}
`;

export const Subtext = styled(Body)`
	font-size: 0.6em;
	margin: 50px auto 0;
	color: darkgrey;
	max-width: 650px;
`;

export const ButtonText = styled(Body)`
	margin: 0 auto 30px;
	cursor: pointer;
	-webkit-mask-image: ${({ theme }) =>
		`-webkit-linear-gradient(0deg, ${theme.accentColor}, ${theme.accentSecondaryColor})`};
	mask-image: ${({ theme }) => `-webkit-linear-gradient(0deg, ${theme.accentColor}, ${theme.accentSecondaryColor})`};
	background: ${({ theme }) => `-webkit-linear-gradient(0deg, ${theme.accentColor}, ${theme.accentSecondaryColor})`};
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
`;

export const ErrorText = styled(Body)`
	margin: 10 auto 0;
	font-size: 0.9em;
	color: ${({ theme }) => theme.errorColor};
	width: 450px;
	${below.sm`
    width: 80%;
  `};
`;
