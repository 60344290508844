import styled, { keyframes } from "styled-components";
import { fadeInUp } from "react-animations";

import { Wrapper, below } from "./utils";
import { Body } from "./typography";
import { HeaderHome } from "./home";
import { NativeSlider } from "./calculator";
import {
	goalsSlideLeft,
	goalsSlideRight,
	goalsSlideLeftSmall,
	goalsSlideRightSmall,
} from "./keyframes";

const fadeInUpAnimation = keyframes`${fadeInUp}`;

export const SliderWrapper = styled(Wrapper)`
	height: auto;

	&.viewed {
		animation: 0.9s ${fadeInUpAnimation} 0.8s both;
	}
`;

export const HomeSlider = styled(NativeSlider)`
	&.MuiSlider-root {
		color: #4b43f1 !important;
		width: 100% !important;
	}
`;

export const InputContainer = styled.div`
	width: 85%;
	position: relative;
	margin-bottom: 60px;
	${below.xs`
    margin-bottom: 40px;
  `}
`;

export const SliderContainer = styled(Wrapper)`
	width: 850px;
	z-index: 0;
	height: auto;
	${below.lg`
    width: 720px;
  `};
	${below.sm`
    width: 100%;
  `};
`;

export const SliderBlocks = styled(Wrapper)`
	position: absolute;
	height: 70px;
	top: -40px;
	${below.sm`
    top: -30px;
  `};
`;

export const SlideImg = styled.img`
  width: 220px;
  margin-bottom: 90px;
  ${below.sm`
    margin-bottom: 70px;
  `}
  ${below.xs`
    width: 170px;
  `}
  ${below.xxs`
    width: ${({ iOS }) => (iOS ? `150px` : `170px`)};
    margin-bottom: ${({ iOS }) => (iOS ? `50px` : `70px`)};
  `};
  &#goals2 {
    position: absolute;
    z-index: -1;
  }

  &.two2 {
    &#goals1 {
      animation: ${goalsSlideLeft} 1s ease-in-out 1.5s both;
      ${below.xs`
        animation: ${goalsSlideLeftSmall} 1s ease-in-out 1.5s both;
      `}
    }
    &#goals2 {
      animation: ${goalsSlideRight} 1s ease-in-out 1.5s both;
      ${below.xs`
        animation: ${goalsSlideRightSmall} 1s ease-in-out 1.5s both;
      `}
    }
  }
`;

export const Text = styled(HeaderHome)`
	margin-bottom: 20px;
	width: 100%;
	text-align: center;
	${below.xxs`
    margin-bottom: ${({ iOS }) => (iOS ? `7px` : `20px`)};
  `};
`;

export const SlideContainer = styled(Wrapper)`
	position: absolute;
	top: 0;
	align-items: center;
	height: auto;
	opacity: 0;
	transition: 0.2s ease-in-out;
	&:nth-of-type(1) {
		position: relative;
	}

	&.one1,
	&.two2,
	&.three3,
	&.four4,
	&.five5,
	&.six6 {
		opacity: 1;
		transition: 0.3s ease-in-out 0.2s;
	}
`;

export const Icon = styled.img`
	width: 25px;
	height: 21px;
	opacity: 0.2;
	transition: 0.1s linear;
	${below.sm`
    width: 20px;
    height: 17px;
  `};

	&.one1,
	&.two2,
	&.three3,
	&.four4,
	&.five5,
	&.six6 {
		opacity: 1;
		transition: 0.2s ease-in-out;
	}
`;

export const SliderText = styled(Body)`
	margin: 0;
	opacity: 0;
	text-align: center;
	transition: 0.1s linear;
	${below.sm`
    /* display: none; */
  `};

	&.one1,
	&.two2,
	&.three3,
	&.four4,
	&.five5,
	&.six6 {
		opacity: 1;
		transition: 0.2s ease-in-out;
	}
`;
