import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { fadeInUp } from 'react-animations';

import scrollDown from '../../images/home/scroll-down.svg';
import { Header, Headline } from './typography';
import { below, above } from './utils';
import { slideUp } from './keyframes';

const fadeInUpAnimation = keyframes`${fadeInUp}`;

export const Section = styled.div`
	position: relative;
	text-align: center;

	& .iScrollVerticalScrollbar {
		display: none !important;
	}
`;

export const LoadOverlay = styled.div`
	position: absolute;
	top: 80px;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: 100;
	background: ${p => p.theme.primaryColor};
`;

export const HeaderHome = styled(Header)`
	margin: 0;
	${below.xxs`
    line-height: 1.1;
  `};

	&.viewed {
		animation: ${({ animationDelay }) => css`1s ${fadeInUpAnimation} ${animationDelay ?? '0.7s'} both`};
	}
`;

export const HeadlineHome = styled(Headline)`
	&.viewed {
		animation: ${({ animationDelay }) => css`1s ${fadeInUpAnimation} ${animationDelay ?? '1.2s'} both`};
	}
`;

export const BottomSpace = styled(({ height, ...rest }) => <div {...rest} />)`
	height: ${({ height }) => height ?? '100px'};
	${above.sm`
    display: none;
  `};
`;

export const ScrollDown = styled(({ start, ...rest }) => <img src={scrollDown} alt="scroll down" {...rest} />)`
	position: absolute;
	cursor: pointer;
	bottom: 15px;
	left: 50%;
	margin-left: -20px;
	opacity: 0;
	width: 40px;
	${below.sm`
    display: none;
  `};

	&.viewed {
		animation: ${({ animationDelay }) => css`1s ${fadeInUpAnimation} ${animationDelay ?? '0.9s'} both`};
	}
`;
