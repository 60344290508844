import styled from "styled-components";

import { Wrapper, below } from "./utils";
import { HeaderHome } from "./home";
import { Body } from "./typography";
import {
	fadeIn,
	fadeOut,
	fadeInOut,
	fadeInOutFadedFull,
	fadeInOutFadedHalf,
} from "./keyframes";

export const AppleContainer = styled(Wrapper)``;

export const AppleWrapper = styled(Wrapper)``;

export const AppleHeader = styled(HeaderHome)`
	margin-bottom: 40px;
	max-width: 550px;
	line-height: 1.3;
	opacity: 0;
	${below.sm`
    margin-bottom: 20px;
    font-size: 27px;
  `};
	${below.xs`
    font-size: 27px;
  `};
	${below.xxs`
    font-size: 24px;
  `};

	&.logoHeader {
		opacity: 1;
	}
`;

export const AppleWatchContainer = styled(Wrapper)`
	height: auto;
	width: auto;
`;

export const AppleCardContainer = styled(Wrapper)`
	height: auto;
	width: auto;
`;

// export const AppleCircleContainer = styled(Wrapper)`
// 	height: auto;
// 	position: absolute;
// 	top: 385px;
// 	opacity: 0;
// 	${below.md`
//     top: 330px;
//   `};
// 	${below.sm`
//     top: 235px;
//   `};
// 	${below.xxs`
//     top: 180px;
//   `};
// 	&.view {
// 		animation: 8.5s ${fadeInOut} ease-in-out 4s both;
// 	}
// `;

export const AppleSubHeaderWrapper = styled(Wrapper)`
	height: auto;
	width: auto;
`;

export const AppleSubHeader = styled(Body)`
	font-size: 35px;
	position: absolute;
	text-align: center;
	line-height: 1.1;
	opacity: 0;
	&:nth-of-type(1) {
		position: relative;
	}
	${below.sm`
    font-size: 24px;
    margin: 10px 0;
  `};
	${below.xxs`
    font-size: 20px;
    margin: 10px 0;
  `};

	&.view {
		&:nth-of-type(1) {
			animation: 2.3s ${fadeInOut} ease-in-out 1.7s both;
		}
		&:nth-of-type(2) {
			animation: 2s ${fadeInOut} ease-in-out 4s both;
		}
		&:nth-of-type(3) {
			animation: 2.5s ${fadeInOut} ease-in-out 6s both;
		}
		&:nth-of-type(4) {
			animation: 2s ${fadeInOut} ease-in-out 8.5s both;
		}
		&:nth-of-type(5) {
			animation: 0.5s ${fadeIn} ease-in-out 10.5s both;
		}
	}
`;

export const AppleWatchTextWrapper = styled(Wrapper)`
	height: auto;
	position: absolute;
	top: 315px;
	${below.md`
    top: 260px;
  `};
	${below.sm`
    top: 180px;
  `};
	${below.xxs`
    top: 137px;
    line-height: 1.2;
  `};
	&.header {
		top: 125px;
		${below.md`
      top: 95px;
    `};
		${below.sm`
      top: 65px;
    `};
		${below.xxs`
      top: 50px;
    `};
	}
`;

export const AppleWatchText = styled(Body)`
	font-size: 0.95rem;
	position: absolute;
	text-align: center;
	width: 100%;
	opacity: 0;
	${below.sm`
    font-size: 0.76rem;
  `};
	${below.xxs`
    font-size: 0.70rem;
  `};
	&:nth-of-type(1) {
		position: relative;
	}
	&.header {
		font-size: 23px;
		${below.sm`
      font-size: 19px;
    `};
		${below.xxs`
      font-size: 16px;
    `};
	}
	&.view {
		&:nth-of-type(1) {
			animation: 4.5s ${fadeInOut} ease-in-out 4s both;
		}
		&:nth-of-type(2) {
			animation: 0.5s ${fadeIn} ease-in-out 8.5s both;
		}
	}
`;

// export const AppleCircle = styled.div`
// 	height: 5px;
// 	width: 5px;
// 	background: #fff;
// 	margin: 0 5px;
// 	border-radius: 50%;
// 	opacity: 0;
// 	${below.sm`
//     height: 4px;
//     width: 4px;
//   `};

// 	&.view {
// 		animation: 8.5s ${fadeInOutFadedFull} ease-in-out 4s both;
// 		&:nth-of-type(1) {
// 			animation: 1.5s ${fadeInOutFadedHalf} ease-in-out 4s both;
// 		}
// 		&:nth-of-type(2) {
// 			animation: 1.5s ${fadeInOutFadedHalf} ease-in-out 5.5s both,
// 				6.5s ${fadeInOutFadedHalf} ease-in-out 8.5s;
// 		}
// 		&:nth-of-type(3) {
// 			animation: 1.5s ${fadeInOutFadedHalf} ease-in-out 7s both;
// 		}
// 	}
// `;

export const Watch = styled.img`
	width: 300px;
	opacity: 0;
	z-index: -1;

	${below.md`
    width: 250px;
  `};
	${below.sm`
    width: 180px;
  `};
	${below.xxs`
    width: 140px;
  `};

	&.viewed {
		opacity: 1;
		transition: 1.4s all 1.1s;
	}
`;

export const AppleFace = styled.img`
	width: 200px;
	position: absolute;
	top: 150px;
	/* z-index: 7; */
	opacity: 0;
	&.viewed {
		opacity: 1;
		transition: 1.4s all 1.1s;
	}
	&.view {
		animation: 0.8s ${fadeOut} ease-in-out 3.3s forwards;
	}
	${below.md`
    width: 160px;
    top: 130px;
  `};
	${below.sm`
    width: 120px;
    top: 93px;
  `};
	${below.xxs`
    width: 90px;
    top: 73px;
  `};
`;

export const CheckMark = styled.img`
	width: 25px;
	margin-top: 25px;
	${below.xxs`
    margin-top: 15px;
    width: 20px;
  `};
`;

export const AppleCard = styled.img`
	width: 185px;
	position: absolute;
	top: 195px;
	opacity: 0;
	${below.md`
    top: 160px;
    width: 155px;
  `};
	${below.sm`
    top: 120px;
    width: 100px;
  `};
	${below.xxs`
    top: 92px;
    width: 80px;
  `};
	&.view {
		/* &.rainbow {
			animation: 1.5s ${fadeInOut} ease-in-out 4s both;
		} */
		&.red {
			animation: 1.5s ${fadeIn} ease-in-out 4s forwards;
		}
		/* &.red {
			animation: 1.5s ${fadeInOut} ease-in-out 5.5s,
				0.5s ${fadeIn} ease-in-out 8.5s forwards;
		} */
		/* &.black {
			animation: 1.5s ${fadeInOut} ease-in-out 7s both;
		} */
	}
`;

export const ApplePos = styled.img`
	width: 200px;
	top: 100px;
	left: -500px;
	position: absolute;
	opacity: 0;
	${below.md`
    width: 170px;
  `};
	${below.sm`
    width: 80px;
  `};
	${below.xxs`
    width: 70px;
    top: 80px;
  `};
	&.view {
		animation: 2.5s ${fadeInOut} ease-in-out 6s both;
		transform: translateX(200px);
		transition: 1s all 6s;
		${below.md`
      transform: translateX(240px);
    `};
		${below.sm`
      transform: translateX(390px);
    `};
		${below.xxs`
      transform: translateX(410px);
    `};
	}
`;

export const AppleSignal = styled.img`
	top: 240px;
	left: -60px;
	position: absolute;
	opacity: 0;
	${below.md`
    top: 210px;
  `};
	${below.sm`
    top: 160px;
    left: -25px;
    width: 20px;
  `};
	${below.xxs`
    top: 125px;
    left: -18px;
    width: 15px;
  `};
	&.view {
		animation: 2.5s ${fadeInOut} ease-in-out 6s both;
	}
`;

export const AppleArrows = styled.img`
	width: 22px;
	top: 310px;
	right: -50px;
	position: absolute;
	opacity: 0;
	${below.md`
    top: 260px;
  `};
	${below.sm`
    top: 185px;
    width: 20px;
  `};
	${below.xxs`
    top: 145px;
    width: 17px;
  `};
	&.view {
		animation: 1.3s ${fadeInOutFadedFull} ease-in-out 2.5s both;
		transform: translateX(-25px);
		transition: 1s all 2.5s;
		${below.xxs`
      transform: translateX(-30px);
    `};
	}
`;
