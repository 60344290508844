import React from 'react';
import styled, { keyframes } from 'styled-components';
import { fadeInUp, zoomInDown } from 'react-animations';
import { Apple, PlayStore } from '@styled-icons/boxicons-logos';

import { Wrapper, below, above } from './utils';
import { Body, Display, Header } from './typography';
import { HeadlineHome, HeaderHome } from './home';

const fadeInUpAnimation = keyframes`${fadeInUp}`;
const zoomInDownAnimation = keyframes`${zoomInDown}`;

export const ComingText = styled(Body)`
	color: ${({ theme }) => theme.grayScaleFadedColor};
	margin: 20px 0 15px;
	${below.md`
    text-align: center;
    margin: 10px auto;
  `};
	${below.xxs`
    margin: ${({ iOS }) => (iOS ? '5px auto' : '10px auto')};
  `};

	${above.sm`
    display: none;
  `};
`;

export const RHText = styled(Body)`
	color: ${({ theme }) => theme.grayScaleFadedColor};
	margin: 7px 0 0;
	${below.md`
    text-align: center;
    font-size: 14px;
  `};
`;

export const RHClick = styled.span`
	color: ${({ theme }) => theme.accentColor};
	border-bottom: 1px solid ${({ theme }) => theme.accentColor};
	cursor: pointer;
`;

export const Clients = styled(Wrapper)`
	/* height: 30px; */
	/* ${below.md`
    display: none;
  `}; */
`;

export const ClientWrapper = styled(Wrapper)`
	width: auto;
	height: 60px;
	margin: 10px;
`;

export const ClientText = styled(Body)`
	font-size: 13.5px;
	font-weight: 600;
	margin: 0;
	color: ${({ theme }) => theme.grayScaleFadedColor};
	line-height: 2;
`;

export const ClientImg = styled.img`
	height: 30px;
	/* opacity: 0.9; */
`;

export const HeaderLanding = styled(HeaderHome)`
	width: 570px;
	font-size: 70px;
	margin-bottom: 20px;
	${below.lg`
    width: 530px;
  `};
	${below.md`
    width: 100%;
    font-size: 70px;
  `};
	${below.xs`
    font-size: 55px;
    margin-bottom: 10px;
  `};
`;

export const HeadlineLanding = styled(HeadlineHome)`
	width: 530px;
	margin-bottom: 30px;
	${below.md`
    font-size: 17px;
    text-align: center;
    margin: 15px auto;
  `};
	${below.sm`
    max-width: 100%;
  `};
	${below.xxs`
    margin: ${({ iOS }) => (iOS ? '8px auto' : '15px auto')};
  `};
`;

export const HeroImg = styled.img`
	max-width: 49vw;
	max-height: 70vh;
 	/* max-width: 550px; */
	/* position: absolute; */
	/* left: 50%; */
	/* bottom: -120px; */
	opacity: 0;
	${below.lg`
    max-width: 43vw;
	  /* right: 0;
    bottom: -100px; */
  `};
	${below.md`
    max-width: 95vw;
    max-height: 50vh; 
    /* position: initial;
    max-width: 370px; */
    margin: 15px auto 0;
  `};
	/* ${below.sm`
    max-width: 270px;
    margin-bottom: 8px;
    @media (min-height: 750px) {
      max-width: 320px;
      margin-bottom: 15px;
    }
  `};
	${below.xxs`
    max-width: ${({ iOS }) => (iOS ? '200px' : '280px')};
    height: ${({ iOS }) => (iOS ? '200px' : '250px')};
    @media (min-height: 750px) {
      height: ${({ iOS }) => (iOS ? '260px' : '300px')};
      margin-bottom: 15px;
    }
  `}; */

	&.viewed {
		opacity: 1;
		animation: 0.8s ${zoomInDownAnimation} ease-in-out 0.7s both;
	}
`;

export const LandingTitleText = styled(Body)`
	font-size: 1.5em;
	margin-top: 0;
	${below.md`
    font-size: 1.1em !important;
  `};
`;

export const LandingText = styled.div`
	text-align: left;
	${below.md`
    text-align: center;
  `};
`;

export const LandingSection = styled(Wrapper)`
	height: auto;
	/* margin-bottom: 90px; */
	opacity: 0;
	${below.md`
    flex-direction: column-reverse;
    /* margin-bottom: 50px; */
  `};

	&.viewed {
		animation: 1s ${fadeInUpAnimation} both;
	}
`;

export const PhoneWrapper = styled(({ email, ...rest }) => <Wrapper {...rest} />)`
	height: auto;
	margin-top: 20px;
	&.viewed {
		animation: 1s ${fadeInUpAnimation} both;
	}
	${below.sm`
    display: ${({ email }) => (email ? 'flex' : 'none !important')};
  `};

	&.signUp {
		align-items: center;
		margin-top: 0px;
	}
`;

export const LandingCaption = styled(Body)`
	font-size: 0.7em;
	width: 360px;
	padding-top: 10px;
	opacity: 0.5;

	${below.md`
	  width: 100%;
    margin: 0 50px 0 15px;
    /* text-align: center; */
    padding-top: 0;
    font-size: 0.60em !important;
  `};
`;

export const StoreImg = styled.img`
	margin-top: 15px;
	width: 160px;
	cursor: pointer;
	&:nth-of-type(1) {
		display: ${({ iOS }) => (iOS ? 'initial' : 'none')};
	}
	&:nth-of-type(2) {
		display: ${({ iOS }) => (iOS ? 'none' : 'initial')};
	}

	${above.sm`
    display: none !important;
  `};
`;

// Appstore Hover Button
export const DownloadButtonHome = styled.div`
	cursor: pointer;
	background-color: ${({ theme, destination }) => {
		if (destination === 2 || destination === 4 || destination === 6 || destination === 8) {
			return theme.accentSecondaryColor;
		} else {
			return theme.accentColor;
		}
	}};
	position: absolute;
	bottom: 0;
	left: 57%;
	margin: 10px;
	border-radius: 5px;
	text-align: center;
	padding: 5px 10px;
	display: flex;
	flex-direction: row;
	opacity: ${({ destination }) => (destination === 0 || destination === 9 || destination === 10 ? 0 : 1)};
	align-items: center;
	z-index: 2;
	transition: all 0.8s;

	${below.xxs`
    margin: 8px;
    padding: 4px 6px;
  `};

	${above.sm`
    display: none !important;
  `};

	& p {
		margin: 0 !important;
		font-family: 'Conv_EuclidCircularB-Bold';
		font-size: 0.9rem;

		${below.xxs`
      font-size: 0.8rem;  
    `};
	}
`;

export const AppStoreLogo = styled(Apple)`
	color: ${({ theme }) => theme.primaryColor};
	width: 33px;
	margin-right: 5px;
	${below.xxs`
    width: 25px;
  `};
`;

export const PlayStoreLogo = styled(PlayStore)`
	color: ${({ theme }) => theme.primaryColor};
	width: 33px;
	margin-right: 5px;
	${below.xxs`
    width: 25px;
  `};
`;

// ---------------------------------------------------
// Mission Section 2
// ---------------------------------------------------
export const MissionDisplay = styled(Display)`
	display: block;
	-webkit-mask-image: -webkit-linear-gradient(180deg, #7bfff5, #807bff);
	mask-image: -webkit-linear-gradient(180deg, #7bfff5, #807bff);
	background: -webkit-linear-gradient(180deg, #7bfff5, #807bff);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	margin: 0 auto;
	width: 85%;
	${below.sm`
    line-height: 1em;
    font-size: 35px
    width: 100%;
  `};
	${below.xxs`
    font-size: 30px
  `};
`;

export const MissionHeader = styled(Header)`
	font-family: 'Conv_EuclidCircularB-Regular';
	font-weight: 400;
	margin: 0 auto;
	margin-top: 40px;
	font-size: 30px;
	width: 75%;
	color: #aeb0b0;
	opacity: 0.65;
	${below.md`
    font-size: 28px;
  `};
	${below.sm`
    line-height: 1em;
    font-size: 25px;
    width: 92%;
    margin-top: 30px;
  `};
	${below.xxs`
    font-size: 22px;
  `};
`;

export const TextTooltip = styled.span`
	position: relative;
	cursor: help;
	border-radius: 50%;
	margin: 0 5px;
	color: rgba(256, 256, 256, 0.6);
	font-size: ${({ size }) => size ?? '14px'};
	font-family: 'Conv_EuclidCircularB-Regular';
	font-weight: 400;
	z-index: 10;

	&::before,
	&::after {
		position: absolute;
		left: 50%;
		opacity: 0;
		display: none;
		transition: all ease 0.3s;
		z-index: 10;
	}

	&::before {
		content: '';
		border-width: 6px 4px 0px;
		border-style: solid;
		border-color: #2a2d4d transparent transparent transparent;
		margin-left: -4px;
		top: ${({ top }) => (top ? '-8px' : null)};
		bottom: ${({ top }) => (top ? null : '-3px')};
	}

	&::after {
		content: attr(data-tooltip);
		background: #2a2d4d;
		width: 150px;
		font-size: 13px;
		bottom: ${({ top }) => (top ? null : '-3px')};
		top: ${({ top }) => (top ? '-8px' : null)};
		margin-left: -75px;
		text-align: center;
		padding: 10px;
		border-radius: 5px;
		transform: ${({ top }) => (top ? 'translateY(-100%)' : 'translateY(100%)')};
		${below.sm`
      margin-left: -140px;
    `};
	}

	&:hover::before,
	&:hover::after {
		opacity: 1;
		display: block;
	}

	& img {
		width: ${({ size }) => size ?? '14px'};
		height: ${({ size }) => size ?? '14px'};
	}
`;
