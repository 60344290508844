import styled from 'styled-components';

export const PaginationContainer = styled.nav`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 50px 0 0;

	& a {
		display: inline-block;
		padding: 7px 10px;
		border: ${({ theme }) => theme.accentColor} 1px solid;
		color: ${({ theme }) => theme.accentColor};
		text-decoration: none;
		line-height: 1em;
		border-radius: 10px;
	}
`;

export const PaginationLocation = styled.div`
	position: absolute;
	left: 50%;
	width: 100px;
	margin-left: -50px;
	text-align: center;
	color: ${({ theme }) => theme.grayScaleColor};
`;
