import React from 'react';
import styled from 'styled-components';
import { getColor, below } from './utils';

export const Button = styled(
	({ small, round, fill, right, color, primary, secondary, accent, grayScale, submit, alignSelf, ...rest }) => (
		<button type={submit ? 'submit' : 'button'} {...rest} />
	)
)`
	padding: ${({ small }) => (small ? '10px 16px' : '16px 24px')};
	border-radius: ${({ round }) => (round ? '10px' : '5px')};
	border: none;
	outline: none;
	color: ${({ theme }) => theme.grayScaleColor};
	cursor: pointer;
	position: relative;
	margin: 0;
	font-size: 16px;
	font-family: 'Conv_EuclidCircularB-Regular';
	text-align: center;
	width: ${({ fill }) => (fill ? '100%' : 'auto')};
	align-self: ${({ alignSelf }) => alignSelf || 'initial'};
	background: ${props => getColor(props)};
	float: ${({ right }) => (right ? 'right' : null)};
	${below.sm`
    padding: ${({ small }) => (small ? '8px 14px' : '14px 20px')};
  `};
`;

export const GradientButton = styled(Button)`
	/* background: rgb(118, 50, 251);
	background-image: linear-gradient(
		to right,
		rgb(118, 50, 251) 0%,
		rgb(52, 96, 253) 51%,
		rgb(118, 50, 251) 100%
	); */
	background: ${({ theme }) => theme.accentColor};
	color: ${({ theme }) => theme.grayScaleColor};
	margin: 0 0 20px;
	background-size: 200% auto;
	transition: all 0.2s ease-in-out;
	width: auto;
	max-width: ${({ width }) => width || 'auto'};
	&:hover {
		background-position: right center;
		outline: none;
		transition: all 0.2s ease-in-out;
	}

	&.forum {
		background: orange;
	}
	&.discord {
		background: green;
	}

	&#careers {
		margin-top: 20px;
	}

	&#jobApply {
		margin-bottom: 0;
		${below.sm`
      margin-top: 8px;
    `};
	}

	&#applySubmit {
		margin-top: 8px;
	}
`;

export const DownloadButtonWrapper = styled(Button)`
	background: ${({ theme }) => theme.accentColor};
	color: ${({ theme }) => theme.grayScaleColor};
	border-radius: 10px;
	margin-top: 20px;
	padding: 16px 50px;
	${below.sm`
    display: none;
  `};

	&.permanent {
		display: block !important;
	}

	&.minWidth {
		margin-right: auto;
	}
`;
