import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';
import Modal from 'styled-react-modal';

import { below, Wrapper } from './utils';
import { Button } from './buttons';
import { Header, Headline } from './typography';

const fadeInAnimation = keyframes`${fadeIn}`;

export const StyledModal = Modal.styled`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 15px;
  padding: 10px 60px 30px;
  overflow: auto;
  border-radius: 20px;
  background-color:${props => props.theme.secondaryColor};
  max-height: 95vh;

  ${below.xs`
    padding: 0 20px;
  `};

  &#RHModal {
    ${below.sm`
    padding: 0 20px 30px;
  `};
  }
`;

export const StyledModalContact = Modal.styled`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 15px;
  padding: 10px 60px 30px;
  border-radius: 20px;
  background-color:${props => props.theme.secondaryColor};

  ${below.sm`
    max-height: 80vh;
  `};

  ${below.xs`
    padding: 0 20px;
  `};
`;

export const ModalContainer = styled.div`
	min-height: 101%;
	overflow: auto !important;
	text-overflow: auto !important;
	-webkit-overflow-scrolling: auto !important;
`;

export const SpecialModalBackground = styled.div`
	position: fixed;
	top: 0;
	height: 100vh;
	left: 0;
	overflow: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	z-index: 1000;
	background-color: ${props => props.theme.primaryColor + 'e0'};
`;

export const ModalClose = styled(Button)`
	background: none;
	align-self: flex-end;
	color: ${({ theme }) => theme.grayScaleColor};
	z-index: 30;
	padding: 0 0 20px;
	font-size: 31px;

	${below.xs`
    padding: 0;
  `};
`;

export const ModalWrapper = styled(Wrapper)`
	height: auto;
	width: auto;
	${below.sm`
    flex-direction: column;
    justify-content: center;
  `};

	&.true {
		animation: 1s ${fadeInAnimation} both;
	}
`;

export const ModalText = styled.div`
	width: 350px;
	${below.sm`
    width: 90%;
    text-align: center;
    margin-bottom: 50px;
  `};

	&.contact {
		padding: 0 0 20px;
		width: 50%;
		${below.md`
      width: 90%;
      margin-bottom: 10px;
    `};
	}
`;

export const ModalHeader = styled(Header)`
	margin: 0;
	${below.sm`
    text-align: center;
  `};
`;

export const ModalHeadline = styled(Headline)`
	margin: 0;
	${below.sm`
    text-align: center;
  `};
`;

export const ModalImg = styled.img`
	width: 100%;
	${below.xs`
    width: 65%;
  `};
`;

export const ModalMap = styled.img`
	width: 90%;
	border-radius: 10px;
	margin: 20px 0 20px;

	${below.sm`
    display: none;
  `};
`;

export const ModalSpinner = styled(Wrapper)`
	justify-content: center;
	align-items: center;
	height: 250px;
`;

export const ModalImgBox = styled.div`
	width: 380px;
	margin-bottom: 20px;
	text-align: center;
	${below.lg`
    width: 325px;
  `};
	${below.md`
    width: 265px;
  `};
	${below.xs`
    width: 100%;
  `};
`;
