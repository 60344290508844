import styled from 'styled-components';
import { Body, Title } from './typography';
import { getColor, getPosition, below, Wrapper } from './utils';

export const DemoVideo = styled.div`
	border-radius: 50px;
	box-shadow: 0px 1px 5px 1px #000000;
	overflow: hidden;
	${below.md`
    border-radius: 30px;
  `};
`;

export const HashImg = styled.img`
	${below.md`
    width: 200px;
  `};
`;

export const FeatureScreenImg = styled.img`
	width: 300px;
	margin: 0 auto;
	${below.md`
    width: 130px;
  `};
`;

export const TableImg = styled.img`
	width: 90%;
	margin: 0 auto;
	${below.md`
    width: 100%
  `};
`;

export const HashImgContainer = styled.div`
	text-align: center;
	margin-bottom: 15px;
`;

export const TableContainer = styled(Wrapper)`
	margin: 0px auto;
	width: 100%;
	border-radius: 15px;
	overflow: hidden;
`;

export const TableRow = styled(Wrapper)`
	flex-direction: row;
	background: ${({ theme }) => theme.secondaryColor};

	&:nth-of-type(2n) {
		background: ${({ theme }) => `${theme.secondaryColor}80`};
	}

	&:nth-of-type(1) {
		border-bottom: 1px solid ${({ theme }) => theme.grayScaleColor + '80'};
	}
`;

export const TableBody = styled(Body)`
	flex: 1;
	padding: 15px;
	margin: 0;

	&:nth-of-type(1) {
		font-family: 'Conv_EuclidCircularB-Bold';
	}

	${below.md`
    font-size: 0.85em;
    padding: 8px;
  `};

	&.title {
		font-size: 2.2em;
		font-weight: bold;
		font-family: 'Conv_EuclidCircularB-Bold';
		${below.md`
      font-size: 1em;
    `};
	}
`;

export const TableLogo = styled.img`
	width: 150px;
	${below.md`
    width: 80px;
  `};
`;

export const TableFooter = styled(Body)`
	font-size: 0.9em;
	margin: 5px;
	color: ${({ theme }) => theme.grayScaleFadedColor};
`;

export const SimpleSingupContainer = styled(Wrapper)`
	margin: 50px auto;
	${below.md`
    margin: 25px auto;
  `};
`;

export const FeatureTitle = styled(Title)`
	width: 85%;
	margin-bottom: 0px;
	${below.md`
    text-align: center;
    width: 100%;
  `};
`;

export const FeatureBody = styled(Body)`
	width: 85%;
	${below.md`
    text-align: center;
    width: 90%;
  `};
`;

export const FeatureSection = styled(Wrapper)`
	flex-direction: ${({ direction }) => direction || 'row'};
	margin: 50px 0 20px;

	&.first {
		margin-top: 20px;
		${below.md`
      margin-top: 0px;
    `};
	}

	&.last {
		margin-bottom: 70px;
		${below.md`
      margin-bottom: 40px;
    `};
	}

	${below.md`
    flex-direction: column;
    margin: 40px 0;
  `};
`;
