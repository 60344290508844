import styled from 'styled-components';
import { KeyboardArrowRight } from '@styled-icons/material';
import { Location } from '@styled-icons/fluentui-system-regular';

import { loaderSpin } from './keyframes';
import { Wrapper, below, above } from './utils';
import { Header, Headline, Body, Title } from './typography';

export const ImagesLoader = styled.div`
	display: none;
`;

export const Ulist = styled.ul`
	list-style-type: disc;
	color: ${props => props.theme.grayScaleColor};
	font-size: 20px;
	margin-top: 25px;
	padding-bottom: 70px;
	${below.md`
    font-size: 18px;
  `};
`;

export const Pic1 = styled.img`
	margin: 30px 0 30px 30px;
	${below.lg`
    margin: 30px;
  `};
	${below.sm`
    width: 100%;
    margin: 15px 0;
  `};
`;

export const Pic2 = styled.img`
	margin: 20px 20px;
	${below.lg`
    margin: 20px auto;
    width: 750px;
  `};
	${below.md`
    width: 650px;
    margin-bottom: 35px;
  `};
	${below.sm`
    width: 100%;
  `};
`;

export const Pic3 = styled.img`
	width: 460px;
	margin-bottom: 50px;
	margin-right: 30px;
	${below.lg`
    margin-bottom: 0;
    margin-right: 0;
  `};
	${below.xs`
    width: 100%;
  `};
`;

export const Section1 = styled(Wrapper)`
	${below.lg`
    flex-direction: column;
  `};
`;

export const Section2 = styled(Wrapper)``;

export const Section3 = styled(Wrapper)`
	${below.lg`
    flex-direction: column;
    align-items: flex-start;
  `};
	${below.md`
    align-items: center;
  `};
`;

export const JobsHeader = styled(Header)`
	margin-bottom: 20px;
	${below.md`
    text-align: center;
    margin-bottom: 15px;
  `};
	${below.sm`
    margin-bottom: 10px;
  `};
`;

export const JobsHeadline = styled(Headline)`
	${below.md`
    text-align: center;
  `};

	&.positions {
		margin: 0 auto 30px;
		width: 80%;

		${below.md`
      width: 100%;
    `};
	}
`;

export const OfferHeadline = styled(Headline)`
	margin: 0 auto 100px;
	width: 70%;
	${below.sm`
    width: 95%;
    margin-bottom: 50px;
  `};
`;

export const Offer = styled(Body)`
	padding: 19px;
	position: absolute;
	border-radius: 20px;
	margin: 0;
	bottom: 20px;
	${below.md`
    width: 100%;
    position: relative;
    margin-bottom: 15px;
    padding: 10px 19px;
  `};
`;

export const Offer1 = styled(Offer)`
	background-color: #e0585a;
	border-top-right-radius: 0;
	${above.md`
    left: 20px;
    bottom: 490px;
  `};
	${above.lg`
    bottom: 600px;
    left: 0px;
  `};
`;

export const Offer2 = styled(Offer)`
	background-color: #ee8d2b;
	border-top-left-radius: 0;
	${above.md`
    right: 20px;
    bottom: 490px;
  `};
	${above.lg`
    bottom: 600px;
    right: 0px;
  `};
`;

export const Offer3 = styled(Offer)`
	background-color: #33b7c9;
	border-top-right-radius: 0;
	${above.md`
    left: 20px;
    bottom: 230px;
  `};
	${above.lg`
    bottom: 320px;
    left: 0px;
  `};
`;

export const Offer4 = styled(Offer)`
	background-color: #6955f3;
	border-top-left-radius: 0;
	${above.md`
    right: 20px;
    bottom: 230px;
  `};
	${above.lg`
    bottom: 320px;
    right: 0px;
  `};
`;

export const LocationCont = styled.div`
	flex: 0 0 400px;
	margin-bottom: 30px;
	${below.md`
    flex: 0 0 90%;
  `};
`;

export const LocationText = styled(Body)`
	color: ${props => props.theme.grayScaleFadedColor};
	font-size: 1.2rem;
	font-weight: 600;
	${below.sm`
    font-size: 0.9rem;
  `};
`;

export const LocationImg = styled(Location)`
	width: 40px;
	height: 40px;
	color: ${props => props.theme.accentColor};
	margin-right: 10px;
	${below.sm`
    width: 25px;
    height: 25px;
  `};
`;

export const LocationArrow = styled(KeyboardArrowRight)`
	width: 25px;
	color: ${({ theme }) => theme.grayScaleColor};
`;

export const PositionBox = styled(Wrapper)`
	width: 100%;
	height: auto;
	border-radius: 5px;
	background: ${props => props.theme.secondaryColor};
	justify-content: center;
	margin: 13px 0;
	padding: 0px 20px;
	cursor: pointer;
	${below.sm`
    margin: 6px 0;
  `};
`;

export const PositionCount = styled(Wrapper)`
	border-radius: 50%;
	margin-left: 15px;
	height: 22px;
	padding-top: 2px;
	width: 22px;
	justify-content: center;
	align-items: center;
	background-image: linear-gradient(
		90deg,
		${props => props.theme.accentColor} 0%,
		${props => props.theme.accentSecondaryColor} 100%
	);
	color: #fff;
	font-size: 0.93rem;
`;

// ============================================
// Job PagesContainer
// =============================================

export const JobContainer = styled(Wrapper)`
	background: ${props => props.theme.secondaryColor};
	border-radius: 8px;
	padding: 20px 40px 10px;
	margin: 20px auto 25px;
	width: 750px;

	${below.md`
    width: 100%;
  `};
	${below.sm`
    flex-direction: column;
    padding: 20px 10px 10px;
  `};
`;

export const JobContHead = styled(Wrapper)`
	min-width: 415px;

	${below.sm`
    align-items: center;
    min-width: 0;
  `};
`;

export const JobContButtons = styled(Wrapper)`
	height: auto;
	width: auto;
`;

export const JobHeader = styled(Header)`
	margin: 0;

	${below.sm`
    margin-bottom: 3px;
    font-size: 25px;
  `};
`;

export const JobTextButton = styled(Body)`
	color: ${({ theme }) => theme.accentSecondaryColor};
	border-bottom: 1.5px solid ${({ theme }) => theme.accentSecondaryColor};
	margin-top: 20px;
	letter-spacing: 1.2;
	cursor: pointer;

	${below.sm`
    margin-top: 15px;
  `};
`;

export const JobUL = styled.ul`
	color: ${({ theme }) => theme.grayScaleColor};
	padding-left: 15px;
	list-style-type: disc;

	& li {
		margin: 22px 0;
		font-size: 17px;

		& span {
			font-size: 1rem;
			line-height: 1;
		}
	}
`;

export const JobTitle = styled(Title)`
	margin-top: 30px;
`;

export const JobHeadline = styled(Title)`
	margin-top: 5px;
	font-size: 21px;
	font-weight: 300;

	${below.md`
    font-size: 19px;
  `};

	${below.xs`
    text-align: center;
  `};
`;

export const JobText = styled.div`
	width: 740px;
	margin: 0 auto;

	${below.md`
    width: 100%;
  `};
`;

export const JobBody = styled(Body)`
	& ul {
		list-style-type: disc;
		& li {
			margin: 10px 0;
		}
	}
`;

export const Loader = styled.img`
	width: 50px;
	animation: 2s ${loaderSpin} ease-in-out both infinite;
`;
