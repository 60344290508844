import React, { useState } from 'react';
import { ModalClose, NavButton, NavButtonBack, StyledModal } from '../../styles';

const ModalSignUp = ({ setShowNav, showNav }) => {
	return (
		<>
			<NavButtonBack>
				<NavButton small onClick={() => process.env.NODE_ENV === 'development' ? window.open("https://demo.envel.app/", "_blank") : window.open("https://envel.app/", "_blank")} className="join">
					Launch App
				</NavButton>
			</NavButtonBack>
		</>
	);
};

export default ModalSignUp;
