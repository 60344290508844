import React from 'react';
import styled from 'styled-components';

import { Body, Header } from './typography';
import { below } from './utils';

export const AuthorHeader = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 15px 0 40px;
	${below.sm`
    margin: 15px 0 20px;
  `};
`;

export const AuthorHeaderTitle = styled(Header)`
	margin: 0 0 10px 0 !important;
	font-family: 'Conv_EuclidCircularB-Regular';
`;

export const AuthorHeaderBio = styled(Body)`
	margin: 0;
	font-size: 1.1em;
	${below.sm`
    font-size: 1em; 
  `};
`;

export const AuthorHeaderMeta = styled.div`
	display: flex;
	margin: 10px 0 20px;
`;

export const AuthorHeaderItem = styled(({ ...rest }) => <a target="_blank" rel="noopener noreferrer" {...rest} />)`
	display: block;
	padding: 2px 10px;

	&:first-child {
		padding-left: 0;
	}
`;

export const AuthorHeaderImage = styled.div`
	flex: 0 0 auto;
	margin: 0 10px;
	height: 140px;
	width: 140px;
	border-radius: 100%;
	overflow: hidden;
	& img {
		width: 100%;
		object-fit: cover;
	}
	${below.sm`
    height: 80px;
		width: 80px;
  `};
`;

export const TagHeader = styled.header`
	max-width: 690px;
	margin: 15px 0 30px;
`;

export const TagHeaderTitle = styled(Header)`
	margin: 0 0 10px 0 !important;
	font-family: 'Conv_EuclidCircularB-Regular';
`;

export const TagHeaderBio = styled(Body)`
	margin: 0;
	font-size: 1.1em;
	${below.sm`
    font-size: 1em; 
  `};
`;
