import styled, { keyframes } from "styled-components";
import { fadeInUp } from "react-animations";

import { Body } from "./typography";
import { fadeInOut } from "./keyframes";
import { below } from "./utils";
import { HeadlineHome } from "./home";

const fadeInUpAnimation = keyframes`${fadeInUp}`;

export const SplitHeadline = styled(HeadlineHome)`
	${below.sm`
    width: 90%;
    margin: 10px auto 20px;
  `};
	${below.xxs`
    margin: ${({ iOS }) => (iOS ? `0 auto 8px` : `10px auto 20px`)};
  `};
`;

export const SplitContainer = styled.div`
	position: relative;

	&.viewed5 {
		animation: 1s ${fadeInUpAnimation} ease-in-out 0.7s both;
	}
`;

export const SplitImg = styled.img`
	width: 200px;
	position: absolute;
	top: 0;
	z-index: 1;
	margin-bottom: 50px;
	${below.xxs`
    width: ${({ iOS }) => (iOS ? `170px` : `200px`)};
    margin-bottom: 20px;
  `};

	&:nth-of-type(1) {
		position: relative;
	}
	&:nth-of-type(2),
	&:nth-of-type(3),
	&:nth-of-type(4),
	&:nth-of-type(5) {
		left: calc(50% - 100px);
		opacity: 0;
		${below.xxs`
      left: ${({ iOS }) => (iOS ? `calc(50% - 85px)` : `calc(50% - 100px)`)};
    `};
	}

	&.view5 {
		&:nth-of-type(2) {
			opacity: 1;
			transition: 1s ease-in-out 3s;
		}
		&:nth-of-type(3) {
			opacity: 1;
			transition: 1s ease-in-out 4.5s;
		}
		&:nth-of-type(4) {
			opacity: 1;
			transition: 1s ease-in-out 6s;
		}
		&:nth-of-type(5) {
			opacity: 1;
			transition: 1s ease-in-out 8s;
		}
	}
`;

export const SplitText = styled(Body)`
	position: absolute;
	text-align: left;
	padding: 10px 15px;
	border-radius: 10px;
	opacity: 0;
	z-index: 5;
	background: #3a3e63;
	box-shadow: 0 2px 12p 5px rgba(0, 0, 0, 0.4);

	&:nth-of-type(1) {
		top: 20%;
		left: calc(50% - 330px);
		border-top-right-radius: 0;
		${below.sm`
      left: 3%;
      top: 27%;
    `};
	}
	&:nth-of-type(2) {
		top: 40%;
		right: calc(50% - 310px);
		border-top-left-radius: 0;
		${below.sm`
      right: 3%;
      top: 40%;
    `};
	}
	&:nth-of-type(3) {
		top: 60%;
		left: calc(50% - 290px);
		border-top-right-radius: 0;
		${below.sm`
      left: 3%;
      top: 48%;
    `};
	}

	&.view5 {
		&:nth-of-type(1) {
			animation: ${fadeInOut} 1.5s linear 1.7s;
		}
		&:nth-of-type(2) {
			animation: ${fadeInOut} 3s linear 3.3s;
		}
		&:nth-of-type(3) {
			animation: ${fadeInOut} 2s linear 6.4s;
		}
	}
`;
