import styled, { keyframes } from 'styled-components';
import { fadeInUp, zoomInDown, fadeIn } from 'react-animations';

import { Wrapper, below } from './utils';
import { Body, Header, Headline } from './typography';

const fadeInAnimation = keyframes`${fadeIn}`;
const fadeInUpAnimation = keyframes`${fadeInUp}`;
const zoomInDownAnimation = keyframes`${zoomInDown}`;

export const CrowdHeadline = styled(Headline)`
	font-weight: 600;
	font-size: 24px;
	margin-top: 50px;

	${below.md`
    font-size: 21px;
  `};
`;

export const CrowdHeader = styled(Header)`
	${below.md`
    text-align: center;
  `};
`;

export const CrowdBody = styled(Body)`
	${below.md`
    text-align: center;
  `};
`;

export const CrowdLink = styled.span`
	color: ${({ theme }) => theme.accentSecondaryColor};
	cursor: pointer;
`;

export const CrowdVid = styled.div`
	animation: 0.8s ${fadeInAnimation} ease-in-out 1.3s both;
`;

export const CrowdText = styled.div`
	animation: 0.8s ${fadeInAnimation} ease-in-out 1.6s both;
`;

export const CrowdSection = styled(Wrapper)`
	height: auto;
	margin-bottom: 90px;
	${below.md`
    flex-direction: column-reverse;
    margin-bottom: 50px;
  `};

	animation: 1s ${fadeInUpAnimation} both;
`;

export const CrowdImg = styled.img`
	max-width: 520px;
	${below.lg`
    max-width: 420px;
  `};
	${below.md`
    position: initial;
    max-width: 370px;
    margin: 0 auto 15px;
  `};
	${below.sm`
    max-width: 270px;
    margin-bottom: 8px;
  `};
	${below.xxs`
    /* max-width: ${({ iOS }) => (iOS ? '200px' : '280px')}; */
    height: ${({ iOS }) => (iOS ? '200px' : '250px')};
  `};

	animation: 0.8s ${zoomInDownAnimation} ease-in-out 0.7s both;
`;
