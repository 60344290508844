import React from 'react';
import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';

import arrowDown from '../../images/testing/arrow_down.png';
import { getFlexJustify, getFlexAlign, below, Wrapper } from './utils';
import { GradientButton } from './buttons';
import { Header, Body, Title, ErrorMessage } from './typography';

const fadeInAnimation = keyframes`${fadeIn}`;

export const Form = styled(
	({
		justifyStart,
		justifyEnd,
		justifyAround,
		justifyBetween,
		justifyEvenly,
		justifyCenter,
		alignStart,
		alignEnd,
		alignBaseline,
		alignCenter,
		alignStretch,
		row,
		...rest
	}) => <form {...rest} />
)`
	display: flex;
	flex-direction: ${({ row }) => (row ? 'row' : 'column')};
	justify-content: ${props => getFlexJustify(props)};
	align-items: ${props => getFlexAlign(props)};
	width: 360px;
	margin: 0;
	padding: 0;
	${below.md`
    width: 100%;
  `};
`;

export const InputField = styled(({ width, ...rest }) => <input {...rest} />)`
	width: ${({ width }) => width || '100%'};
	min-width: 100px;
	padding: 6px 12px;
	font-size: 16px;
	height: 43px;
	font-family: 'Conv_EuclidCircularB-Regular';
	outline: none;
	margin-bottom: 15px;
	background: ${({ theme }) => theme.grayScaleVeryFadedColor};
	color: ${props => props.theme.grayScaleColor};
	border: none;
	border-radius: 10px;
	display: block;
	box-sizing: border-box;
	${below.md`
    margin-bottom: 20px;
  `};
	${below.xs`
    margin-bottom: 10px;
  `};

	&::placeholder {
		color: ${props => props.theme.grayScaleFadedColor};
	}

	&.left {
		margin-right: 7px;
		${below.sm`
      margin-right: ${({ sml }) => (sml ? '0' : '7px')};
    `};
	}
	&.right {
		margin-left: 7px;
		${below.sm`
      margin-left: ${({ sml }) => (sml ? '0' : '7px')};
    `};
	}
`;

export const TextArea = styled(({ width, ...rest }) => <textarea {...rest} />)`
	width: ${({ width }) => width || '100%'};
	padding: 6px 12px;
	font-size: 16px;
	font-family: 'Conv_EuclidCircularB-Regular';
	outline: none;
	margin-bottom: 15px;
	background: ${({ theme }) => theme.grayScaleVeryFadedColor};
	color: ${props => props.theme.grayScaleColor};
	border: none;
	border-radius: 10px;
	box-sizing: border-box;

	&::placeholder {
		color: ${({ theme }) => theme.grayScaleFadedColor};
	}

	&.applyText {
		margin-bottom: 8px;
	}
`;

export const SignUpField = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 40px;

	&.true {
		animation: 1.5s ${fadeInAnimation} both;
	}
`;

export const FormButton = styled(GradientButton)`
	margin: 0;
`;

export const FormError = styled(ErrorMessage)`
	position: absolute;
	left: 10px;
	top: -30px;
`;

export const FormCaption = styled(Body)`
	font-size: 0.85em;
	width: 360px;
	padding-top: 10px;
	opacity: 0.5;
`;

export const FormText = styled(Wrapper)`
	width: 500px;
	height: auto;
	${below.md`
    width: 100%;
  `};

	&.testing {
		width: 700px;
		${below.md`
      width: 100%;
    `};
	}
`;

export const FormNames = styled(Wrapper)`
	& input {
		width: 47%;
		${below.md`
      width: 48%;
    `};
		${below.sm`
      width: 100%;
    `};
	}
	${below.sm`
    flex-direction: column;
  `};
`;

export const FormHeader = styled(Header)`
	margin: 0 0 30px;
	text-align: center;

	${below.xs`
    margin-bottom: 30px;
  `};
`;

export const FormTitle = styled(Title)`
	font-size: 22px;
	margin: 0 0 10px;
	text-align: center;
	${below.md`
    font-size: 20px;
  `};
`;

export const FormBody = styled(Body)`
	margin: 0 auto 50px;
	text-align: center;
	max-width: 380px;
	${below.xs`
    margin-bottom: 30px;
  `};
`;

export const PhoneForm = styled(Form)`
	--PhoneInputCountrySelectArrow-color--focus: ${props => props.theme.accentColor};
	--PhoneInputCountryFlag-borderColor--focus: ${props => props.theme.accentColor};
	--PhoneInputCountrySelectArrow-color: ${props => props.theme.grayScaleColor};
	--PhoneInputCountrySelect-marginRight: 8px;

	padding-right: 70px;
	position: relative;
	${below.md`
    max-width: 360px;
  `};

	input.PhoneInputInput {
		min-width: 100px;
		padding: 6px 12px;
		font-size: 16px;
		height: 43px;
		font-family: 'Conv_EuclidCircularB-Regular';
		outline: none;
		background: ${({ theme }) => theme.grayScaleVeryFadedColor};
		color: ${({ theme }) => theme.grayScaleColor};
		border: none;
		border-radius: 10px;
		box-sizing: border-box;
		&::placeholder {
			color: ${({ theme }) => theme.grayScaleFadedColor};
		}
	}

	.PhoneInput {
		width: 100%;
	}

	.PhoneInputCountryIcon {
		width: 35px;
		height: inherit;
	}

	.PhoneInputCountry {
		display: none;
	}
`;

export const SignUpButton = styled(GradientButton)`
	margin: 0;
	padding: 0 5px;
	width: 120px;
	height: 100%;
	position: absolute;
	right: 0;
	display: none;
	${below.sm`
    padding: 0 5px;
  `};
	&:nth-of-type(1) {
		display: inline-block;
	}
	/* &.focused {
		width: 50px;
		display: none;
		&:nth-of-type(2) {
			display: inline-block;
		}
	} */
	&.sending {
		width: 100%;
		display: none;
		&:nth-of-type(2) {
			display: inline-block;
		}
	}
	&.done {
		width: 100%;
		display: none;
		&:nth-of-type(3) {
			display: inline-block;
		}
	}
	&.error {
		width: 100%;
		display: none;
		&:nth-of-type(4) {
			display: inline-block;
		}
	}
`;

export const RHButton = styled(GradientButton)`
	margin: 0;
	padding: 0 5px;
	width: 100px;
	height: 100%;
	position: absolute;
	right: 0;
	${below.sm`
    padding: 0 5px;
  `};
`;

export const EmailInput = styled(InputField)`
	margin-bottom: 0;
	padding-right: 55px;
	${below.md`
    margin-bottom: 0;
  `};
`;

export const RHInput = styled(EmailInput)`
	padding-right: 105px;
`;

export const ArrowImg = styled.img`
	width: 25px;
	margin-top: 5px;
`;

export const ContactForm = styled(Form)`
	width: 50%;
	${below.md`
    width: 50%;
  `};
	${below.sm`
    width: 100%;
  `};

	&#applyForm {
		width: 100% !important;
		margin-top: 20px;
	}

	&#testerForm {
		width: 70% !important;
		margin: 40px 0 30px;

		${below.sm`
      width: 90% !important;
    `};
	}
`;

export const SelectContainer = styled.div`
	position: relative;
	display: block;
	border-radius: 10px;
	width: 100%;
	height: 43px;
	box-sizing: border-box;

	/* &:after {
		content: '';
		width: 0;
		height: 0;
		position: absolute;
		pointer-events: none;
	}

	&:after {
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		top: 0.3em;
		right: 0.75em;
		border-top: 8px solid black;
		opacity: 0.5;
	} */
`;

export const Select = styled(({ width, ...rest }) => <select {...rest} />)`
	width: ${({ width }) => width || '100%'};
	font-size: 16px;
	height: 43px;
	font-family: 'Conv_EuclidCircularB-Regular';
	outline: none;
	color: ${({ theme, faded }) => (!faded ? theme.grayScaleFadedColor : theme.grayScaleColor)};
	border-radius: 10px;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	padding: 6px 12px;
	border-width: 0;
	display: block;
	background: ${() => `url(${arrowDown})`} no-repeat;
	background-size: 12px;
	background-position: calc(100% - 20px) center;
	background-repeat: no-repeat;
	background-color: ${({ theme }) => theme.grayScaleVeryFadedColor};
	&::-ms-expand {
		display: none;
	}

	&.left {
		margin-right: 7px;
		${below.sm`
      margin-right: ${({ sml }) => (sml ? '0' : '7px')};
    `};
	}
	&.right {
		margin-left: 7px;
		${below.sm`
      margin-left: ${({ sml }) => (sml ? '0' : '7px')};
    `};
	}
`;

export const FileButton = styled(Wrapper)`
	color: ${({ theme }) => theme.grayScaleFadedColor};
	background: ${({ theme }) => theme.grayScaleVeryFadedColor};
	border-radius: 5px;
	text-align: center;
	cursor: pointer;
	margin: 7px 0;

	& input {
		position: absolute;
		opacity: 0;
		z-index: 10;
		cursor: pointer;
		right: 0;
		top: 0;
		height: 100%;
		width: 100%;
	}
`;

export const Tooltip = styled.span`
	position: relative;
	background: ${({ files, theme }) =>
		!files ? theme.grayScaleVeryFadedColor : files.type && files.size ? 'green' : 'red'};
	cursor: help;
	padding: 0px 7px;
	border-radius: 50%;
	width: 22px;
	height: 22px;
	margin-left: 5px;
	color: ${({ theme }) => theme.grayScaleFadedColor};

	&::before,
	&::after {
		position: absolute;
		left: 50%;
		opacity: 0;
		display: none;
		transition: all ease 0.3s;
	}

	&::before {
		content: '';
		border-width: 6px 4px 0;
		border-style: solid;
		border-color: ${({ theme }) => theme.secondaryColor} transparent transparent transparent;
		margin-left: -4px;
		top: -8px;
	}

	&::after {
		content: attr(data-tooltip);
		background: ${({ theme }) => theme.secondaryColor};
		width: 150px;
		font-size: 13px;
		top: -8px;
		margin-left: -75px;
		text-align: center;
		padding: 5px;
		border-radius: 5px;
		transform: translateY(-100%);
		${below.sm`
      margin-left: -140px;
    `};
	}

	&:hover::before,
	&:hover::after {
		opacity: 1;
		display: block;
	}
`;
