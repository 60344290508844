import styled, { keyframes } from 'styled-components';
import { zoomIn, pulse } from 'react-animations';

import { Body } from './typography';
import { Wrapper, below, above } from './utils';
import { HeadlineHome, HeaderHome } from './home';
import {
	cashSlideLeft,
	billsSlideRight,
	vaultSlideRight,
	emergencySlideLeft,
	cashSlideLeftMed,
	fadeIn,
	fadeOut,
	textShake,
} from './keyframes';

const zoomInAnimation = keyframes`${zoomIn}`;
const pulseAnimation = keyframes`${pulse}`;

export const EnvelopeBox = styled.div`
	position: relative;
	padding: 10px 13px 20px;
	text-align: left;
	border-radius: 8px;
	overflow: hidden;
	height: 125px;
	width: 100%;
	background-color: ${props => props.color};
	${below.md`
    padding: 4px 10px 0px;
    height: 105px;
  `};
`;

export const SmallText = styled(Body)`
	font-size: 13px;
	margin: 0;
	padding-top: 2px;
`;

export const EnvelopeName = styled(SmallText)`
	font-size: 17px;
`;

export const Img = styled.img`
	height: 58px;
	position: absolute;
	border-style: none;
	top: 43px;
	${below.md`
    height: 46px;
    top: 34px;
  `};
`;

export const Amount = styled(Body)`
	position: absolute;
	font-size: 20px;
	margin: 0;
	padding-top: 9px;
	right: 13px;
	&:nth-of-type(3) {
		opacity: 0;
	}

	&.view {
		&.cash {
			&:nth-of-type(3) {
				animation: 0.1s ${fadeOut} ease-in-out 3.9s both;
			}
			&:nth-of-type(2) {
				animation: 0.1s ${fadeIn} ease-in-out 4s both;
			}
		}
		&.bills {
			&:nth-of-type(3) {
				animation: 0.1s ${fadeOut} ease-in-out 5.9s both;
			}
			&:nth-of-type(2) {
				animation: 0.1s ${fadeIn} ease-in-out 6s both;
			}
		}
		&.vault {
			&:nth-of-type(3) {
				animation: 0.1s ${fadeOut} ease-in-out 7.9s both;
			}
			&:nth-of-type(2) {
				animation: 0.1s ${fadeIn} ease-in-out 8s both;
			}
		}
		&.emergency {
			&:nth-of-type(3) {
				animation: 0.1s ${fadeOut} ease-in-out 9.9s both;
			}
			&:nth-of-type(2) {
				animation: 0.1s ${fadeIn} ease-in-out 10s both;
			}
		}
	}

	&.viewCalc {
		&.cash {
			&:nth-of-type(3) {
				animation: 0.1s ${fadeOut} ease-in-out 0.9s both;
			}
			&:nth-of-type(2) {
				animation: 0.1s ${fadeIn} ease-in-out 1s both;
			}
		}
		&.bills {
			&:nth-of-type(3) {
				animation: 0.1s ${fadeOut} ease-in-out 2.9s both;
			}
			&:nth-of-type(2) {
				animation: 0.1s ${fadeIn} ease-in-out 3s both;
			}
		}
		&.vault {
			&:nth-of-type(3) {
				animation: 0.1s ${fadeOut} ease-in-out 4.9s both;
			}
			&:nth-of-type(2) {
				animation: 0.1s ${fadeIn} ease-in-out 5s both;
			}
		}
		&.emergency {
			&:nth-of-type(3) {
				animation: 0.1s ${fadeOut} ease-in-out 6.9s both;
			}
			&:nth-of-type(2) {
				animation: 0.1s ${fadeIn} ease-in-out 7s both;
			}
		}
	}
`;

export const Month = styled.div`
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 45px;
		height: 1px;
		width: 100%;
		background: rgba(255, 255, 255, 0.2);
	}
	position: absolute;
	font-size: 20px;
	bottom: 10px;
	right: 13px;
	width: 100%;
	padding-top: 10px;
	${below.md`
    bottom: 4px;
    padding-top: 7px;
  `};
	&:nth-of-type(2) {
		opacity: 0;
	}

	&.view {
		&.cash {
			&:nth-of-type(2) {
				animation: 0.1s ${fadeOut} ease-in-out 3.9s both;
			}
			&:nth-of-type(1) {
				animation: 0.1s ${fadeIn} ease-in-out 4s both;
			}
		}
		&.bills {
			&:nth-of-type(2) {
				animation: 0.1s ${fadeOut} ease-in-out 5.9s both;
			}
			&:nth-of-type(1) {
				animation: 0.1s ${fadeIn} ease-in-out 6s both;
			}
		}
		&.vault {
			&:nth-of-type(2) {
				animation: 0.1s ${fadeOut} ease-in-out 7.9s both;
			}
			&:nth-of-type(1) {
				animation: 0.1s ${fadeIn} ease-in-out 8s both;
			}
		}
		&.emergency {
			&:nth-of-type(2) {
				animation: 0.1s ${fadeOut} ease-in-out 9.9s both;
			}
			&:nth-of-type(1) {
				animation: 0.1s ${fadeIn} ease-in-out 10s both;
			}
		}
	}

	&.viewCalc {
		&.cash {
			&:nth-of-type(2) {
				animation: 0.1s ${fadeOut} ease-in-out 0.9s both;
			}
			&:nth-of-type(1) {
				animation: 0.1s ${fadeIn} ease-in-out 1s both;
			}
		}
		&.bills {
			&:nth-of-type(2) {
				animation: 0.1s ${fadeOut} ease-in-out 2.9s both;
			}
			&:nth-of-type(1) {
				animation: 0.1s ${fadeIn} ease-in-out 3s both;
			}
		}
		&.vault {
			&:nth-of-type(2) {
				animation: 0.1s ${fadeOut} ease-in-out 4.9s both;
			}
			&:nth-of-type(1) {
				animation: 0.1s ${fadeIn} ease-in-out 5s both;
			}
		}
		&.emergency {
			&:nth-of-type(2) {
				animation: 0.1s ${fadeOut} ease-in-out 6.9s both;
			}
			&:nth-of-type(1) {
				animation: 0.1s ${fadeIn} ease-in-out 7s both;
			}
		}
	}
`;

// ==========================================
// HOME PAGE SECTION 1
// ==========================================

export const EnvHeaderHome = styled(HeaderHome)`
	${below.xxs`
    line-height: ${({ iOS }) => (iOS ? '1.1' : '1.4')};
  `};
`;

export const EnvSectionWrapper = styled(Wrapper)`
	height: auto;
	${below.md`
    flex-direction: column-reverse;
  `};
`;

export const EnvWrapper = styled(Wrapper)`
	margin-bottom: 50px;
	width: auto;
	height: auto;
	${below.md`
    flex-wrap: wrap;
    margin-bottom: 10px;
  `};
`;

export const HeadlineEnv = styled(HeadlineHome)`
	max-width: 500px;
	${below.xxs`
    line-height: ${({ iOS }) => (iOS ? '1.1' : '1.4')};
    margin-bottom: ${({ iOS }) => (iOS ? '0' : '10px')};;
  `};
`;

export const EnvText = styled(Wrapper)`
	margin-bottom: 50px;
	${below.md`
    margin-bottom: 20px;
  `};
`;

export const EnvContainer = styled.div`
	width: 23%;
	cursor: pointer;
	&.calc {
		cursor: auto;
	}
	${below.md`
    width: 48%;
    margin-bottom: 10px;
    &.view {
      animation: ${zoomInAnimation} 0.9s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.2s both;
    }
  `};
	${above.md`
    &.view:nth-of-type(1){
      animation: ${cashSlideLeftMed} 0.9s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.2s both;
      z-index: 2;
    }
    &.view:nth-of-type(2){
      animation: ${emergencySlideLeft} 0.9s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.2s both;
    }
    &.view:nth-of-type(3){
      animation: ${billsSlideRight} 0.9s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.2s both;
    }
    &.view:nth-of-type(4){
      animation: ${vaultSlideRight} 0.9s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.2s both;
    }
  `};
	${above.lg`
    &.view:nth-of-type(1){
      animation: ${cashSlideLeft} 0.9s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.2s both;
      z-index: 2;
    }
  `};
`;

export const ClickText = styled(Body)`
	margin: 7px 0 0;
	animation: 1s ${textShake} linear 2s both infinite;

	& span {
		font-size: 20px;
	}
`;

export const EnvCashButton = styled.div`
  /* animation: 1s ${pulseAnimation} linear 2s both infinite; */
`;
