import React from 'react';
import styled, { keyframes } from 'styled-components';
import { fadeInUp, zoomInDown } from 'react-animations';

import { Wrapper, below, above } from './utils';
import { Body, Display, Header } from './typography';
import { HeadlineHome, HeaderHome } from './home';

const fadeInUpAnimation = keyframes`${fadeInUp}`;
const zoomInDownAnimation = keyframes`${zoomInDown}`;

// ---------------------------------------------------
// Sections
// ---------------------------------------------------
export const SectionWrapper = styled(({ reverse, ...rest }) => <Wrapper {...rest} />)`
	flex-direction: row;
	align-items: center;
	${below.md`
    flex-direction: ${({ reverse }) => (reverse ? 'column-reverse' : 'column')};
  `};

	& p {
		${below.md`
      margin: 8px 0;
      font-size: 0.9em;
    `};
	}

	&.notFullPage {
		margin: 100px 0;

		${below.md`
      margin: 40px 0;
    `};
	}
`;

export const SectionHeader = styled(({ smaller, ...rest }) => <HeaderHome {...rest} />)`
	margin: ${({ smaller }) => (smaller ? '0 auto 20px' : '0 0 20px')};
	font-size: ${({ smaller }) => (smaller ? '50px' : '60px')};
	width: ${({ smaller }) => (smaller ? '80%' : 'auto')};
	${below.lg`
    font-size: 50px;
  `};
	${below.md`
    text-align: left;
    font-size: 35px;
    margin: 0 0 10px;
    width: auto;
  `};
	${below.xs`
    font-size: 31px;
  `};
`;

export const SectionSubHeader = styled(Body)`
	font-family: 'Conv_EuclidCircularB-Bold';
	color: ${({ theme }) => theme.accentColor};
	letter-spacing: 2px;
	${below.md`
    text-align: left;
  `};
`;

export const SectionImgContainer = styled(Wrapper)`
	width: 100%;
  height: 100%;
  flex-direction: row;
	align-items: center;
	justify-content: center;
	/* &.view {
		animation: 0.7s ${zoomInDownAnimation} 1s both;
	} */
`;

export const SectionVideo = styled(({ start, small, ...rest }) => <Wrapper {...rest} />)`
	height: ${({ small }) => (small ? '50vh' : '60vh')};
	width: auto;
	position: relative;
	justify-content: center;
	align-items: ${({ start }) => (start ? 'flex-start' : 'center')};
	z-index: 2;
	${below.md`
    height: ${({ small }) => (small ? '45vh' : '50vh')};
    margin-top: 15px
  `};
`;

export const SectionImgWrapper = styled(({ start, ...rest }) => <Wrapper {...rest} />)`
	height: 60vh;
	position: relative;
	align-items: ${({ start }) => (start ? 'flex-start' : 'center')};
	z-index: 2;
	${below.md`
    height: 50vh;
    margin-top: 15px
  `};
`;

export const SectionImg = styled.img`
	height: auto;
	width: 100%;
	padding: 0 50px;
	${below.md`
    padding: 0 20px;
  `};
`;

export const SectionTextImage = styled.img`
	height: 40px;
	width: auto;
	margin: 30px auto 0 0;
	${below.md`
    height: 30px;
    margin: 20px auto 0 0;
  `};
`;

export const SectionBannerWrapper = styled(({ image, ...rest }) => <Wrapper {...rest} />)`
	flex-direction: column;
	background: transparent ${({ image }) => `url(${image})`} no-repeat;
	background-size: 100% 100%;
	width: 100%;
	padding: 100px 80px;
	margin: 100px 0;
	${below.md`
    padding: 70px 50px;
    margin: 60px 0;
  `};
	${below.xs`
    padding: 40px 20px;
  `};
`;

export const SectionBannerHeader = styled(HeaderHome)`
	text-align: center;
	font-size: 50px;
	${below.lg`
    font-size: 40px;
  `};
	${below.md`
    font-size: 33px;
  `};
	${below.xs`
    font-size: 28px;
  `};
`;

export const SectionBannerSubHeader = styled(HeadlineHome)`
	text-align: center;
	font-size: 35px;
	padding-top: 20px;
	${below.lg`
    font-size: 30px;
  `};
	${below.md`
    font-size: 25px;
    padding-top: 10px;
  `};
	${below.xs`
    font-size: 20px;
  `};
`;

export const SectionFullVideo = styled.div`
	border-radius: 40px;
	/* box-shadow: 0px 1px 5px 1px #000000; */
	overflow: hidden;
	${below.md`
    border-radius: 30px;
  `};
`;
