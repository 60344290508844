import React, { useContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';

import logo from '../../images/home/envel-logo.png';
import logoLight from '../../images/home/envel-logo-light.png';
import facebook from '../../images/footer/fb.svg';
import instagram from '../../images/footer/in.png';
import linkedIn from '../../images/footer/lk.svg';
import twitter from '../../images/footer/tw.svg';
import { ThemeContext } from '../../providers/ThemeProvider';

import {
	Foot,
	FootHead,
	FooterLink,
	HorRule,
	FooterBrand,
	FooterContainer,
	FooterSection,
	Wrapper,
	FootMedia,
	FootImg,
	FooterSections,
	FootGrey,
	FootGreyWrapper,
	FootGreyLink,
	FooterLogo,
	FooterLinkExt,
	GradientButton,
} from '../../styles';

const Footer = ({ home, footerText, footerAddText }) => {
	let [month, date, year] = new Date().toLocaleDateString('en-US').split('/');
	const { isDarkMode } = useContext(ThemeContext);

	return (
		<StaticQuery
			query={graphql`
				query {
					allGoogleContentNewSheet {
						edges {
							node {
								position
								text
							}
						}
					}
				}
			`}
			render={({ allGoogleContentNewSheet }) => {
				const getContent = text =>
					allGoogleContentNewSheet.edges.find(({ node: { position } }) => position === text)?.node?.text ?? '';

				return (
					<FooterContainer>
						{/* {home && <Partners />} */}
						<HorRule />
						<FooterSections row wrap justifyBetween>
							<FooterSection className="hide-sm">
								<FooterBrand to="/">
									<FooterLogo src={isDarkMode ? logo : logoLight} alt="logo" />
								</FooterBrand>
							</FooterSection>

							<FooterSection>
								<FootHead>Company</FootHead>
								<FooterLink to="/">Home</FooterLink>
								<FooterLink to="/investing">Investments</FooterLink>
								<FooterLink to="/blog/">Blog</FooterLink>
								<FooterLinkExt href="https://discord.com/invite/Brw8d9FjhT" target="_blank" rel="noopener noreferrer">
									Join us on Discord
								</FooterLinkExt>
								<FooterLink to="/jobs/">Careers</FooterLink>
								<FooterLink to="/media/">News</FooterLink>
								<FooterLink to="/faq/">FAQ</FooterLink>
								<FooterLinkExt href="https://support.envel.ai/" target="_blank" rel="noopener noreferrer">
									Support
								</FooterLinkExt>
							</FooterSection>

							<FooterSection>
								<FootHead>Address</FootHead>
								<Foot>Envel</Foot>
								<Foot>75 State Street, Suite 100</Foot>
								<Foot>Boston</Foot>
								<Foot>MA 02109</Foot>
								<a href="mailto:support@envel.ai" target="_blank" rel="noopener noreferrer">
									<Foot>support@envel.ai</Foot>
								</a>
							</FooterSection>

							<FooterSection>
								<FootHead>Follow us</FootHead>
								<Wrapper row>
									<FootMedia href="https://www.facebook.com/envelai/" target="_blank" rel="noopener noreferrer">
										<FootImg src={facebook} alt="facebook" />
									</FootMedia>
									<FootMedia href="https://twitter.com/envel_ai/" target="_blank" rel="noopener noreferrer">
										<FootImg src={twitter} alt="twitter" />
									</FootMedia>
									<FootMedia
										href="https://www.linkedin.com/company/envelbank/"
										target="_blank"
										rel="noopener noreferrer"
									>
										<FootImg src={linkedIn} alt="linkedIn" />
									</FootMedia>
									<FootMedia href="https://www.instagram.com/envel_ai/" target="_blank" rel="noopener noreferrer">
										<FootImg src={instagram} alt="instagram" />
									</FootMedia>
								</Wrapper>
							</FooterSection>

							<FooterSection>
								<FootHead>Get in touch</FootHead>
								{/* <ModalContact /> */}
								<a href="mailto:support@envel.ai" target="_blank" rel="noopener noreferrer">
									<GradientButton small round width="130px" onClick={() => {}}>
										Contact us
									</GradientButton>
								</a>
							</FooterSection>

							<FooterSection className="hide-lg">
								<FooterBrand to="/">
									<FooterLogo className="middle" src={logo} alt="logo" />
								</FooterBrand>
							</FooterSection>
						</FooterSections>
						<FootGreyWrapper alignCenter>
							{footerText ? (
								<FootGrey alignLeft fullWidth dangerouslySetInnerHTML={{ __html: footerText }} />
							) : (
								<>
									<FootGrey dangerouslySetInnerHTML={{ __html: getContent('footer-disclaimer') }} />
									{footerAddText && <FootGrey fullWidth dangerouslySetInnerHTML={{ __html: footerAddText }} />}
								</>
							)}
							<Wrapper row wrap justifyCenter width="auto">
								<Wrapper row width="auto">
									<FootGrey>
										<FootGreyLink to="/terms">Terms of Service</FootGreyLink>
									</FootGrey>
									<FootGrey>
										<FootGreyLink to="/privacy">Privacy Policy</FootGreyLink>
									</FootGrey>
								</Wrapper>
								<FootGrey>© Copyright {year} Envel, Inc. All Rights Reserved</FootGrey>
							</Wrapper>
						</FootGreyWrapper>
					</FooterContainer>
				);
			}}
		/>
	);
};

export default Footer;
