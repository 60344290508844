import styled from 'styled-components';

import { HeaderHome } from './home';
import { Headline } from './typography';
import { Wrapper, below } from './utils';

export const FutureWrapper = styled(Wrapper)`
	flex-direction: row;
	${below.sm`
    flex-direction: column;
  `};
`;

export const FutureSectionWrapper = styled(Wrapper)`
	align-items: center;
	${below.sm`
    justify-content: space-around;
  `};
`;

export const FutureImg = styled.img`
	height: 70vh;
`;

export const FutureNumber = styled(Wrapper)`
	background: ${({ theme }) => theme.secondaryColor};
	border-radius: 50%;
	width: 40px;
	height: 40px;
	justify-content: center;
	align-items: center;
	box-shadow: ${({ theme }) => `0 0 8px 1px ${theme.accentSecondaryColor}`};
	color: white;
	font-size: 1.5em;
	${below.sm`
    margin-right: 20px;
  `};
`;

export const FutureTextWrapper = styled.div`
	display: ${({ small }) => (small ? 'none' : 'flex')};
	width: 60%;
	flex-direction: column;
	align-items: ${({ left }) => (left ? 'flex-end' : 'flex-start')};
	margin-bottom: 50px;
	${below.lg`
    width: 75%;
  `};
	${below.md`
    width: 85%;
  `};
	${below.sm`
    width: 95%;
    align-items: flex-start;
    flex-direction: row;
    margin-bottom: 20px;
    display: ${({ big }) => (big ? 'none' : 'flex')};
  `};

	& p {
		text-align: ${({ left }) => (left ? 'right' : 'left')};
		margin-bottom: 10px;

		${below.sm`
      text-align: left;
      margin-top: 10px;
    `};
	}
`;

export const HeaderFuture = styled(HeaderHome)`
	margin: 0 0 50px;
	${below.sm`
    margin-bottom: 30px;
  `};
`;

export const HeadlineFuture = styled(Headline)`
	${below.sm`
    margin: 0 !important;
  `};
`;

export const FutureImgContainer = styled.div`
	${below.sm`
    display: none;
  `};
`;
