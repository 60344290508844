import styled from 'styled-components';
import { getColor, getPosition, below } from './utils';

export const Display = styled.h1`
	text-align: ${props => getPosition(props)};
	color: ${props => getColor(props)};
	font-size: 44px;
	font-family: 'Conv_EuclidCircularB-Bold';
	${below.md`
    font-size: 40px;
  `};
`;

export const Header = styled.h2`
	text-align: ${props => getPosition(props)};
	color: ${props => getColor(props)};
	font-family: 'Conv_EuclidCircularB-Bold';
	font-size: 40px;
	line-height: 1.2;
	${below.md`
    font-size: 35px;
  `};
	${below.xs`
    font-size: 31px;
  `};
`;

export const Title = styled.h3`
	text-align: ${props => getPosition(props)};
	color: ${props => getColor(props)};
	font-size: ${({ font }) => font || '28px'};
	font-family: ${({ normal }) => (!normal ? 'Conv_EuclidCircularB-Bold' : 'Conv_EuclidCircularB-Regular')};
	margin: 10px 0;
	line-height: 1.2;
	${below.md`
    font-size: 24px;
  `};
`;

export const Headline = styled.p`
	text-align: ${props => getPosition(props)};
	color: ${props => getColor(props)};
	font-size: ${({ font }) => font || '20px'};
	font-family: ${({ bold }) => (bold ? 'Conv_EuclidCircularB-Bold' : 'Conv_EuclidCircularB-Regular')};
	margin: 10px 0;
	line-height: 1.2;
	${below.md`
    font-size: 18px;
  `};
`;

export const Body = styled.p`
	text-align: ${props => getPosition(props)};
	color: ${props => getColor(props)};
	font-size: ${({ font }) => font || '1rem'};
	line-height: 1.3;
`;

export const BodyBold = styled.span`
	font-family: 'Conv_EuclidCircularB-Bold';
	font-size: 1.1em;
`;

export const HeadlineTP = styled(Headline)`
	font-size: 16px;
	font-family: 'Conv_EuclidCircularB-Bold';
`;

export const BodyTP = styled(Body)`
	font-size: 14px;
	margin: 0 0 20px;
	color: ${({ theme }) => theme.grayScaleColor};
	opacity: 0.7;

	& a {
		color: ${({ theme }) => theme.grayScaleColor};
		text-decoration: underline;
	}

	& ol {
		list-style-type: decimal;
	}
	& ul {
		list-style-type: disc;
	}
	& .listUpperRoman {
		list-style-type: upper-roman;
	}
	& .listLowerAlpha {
		list-style-type: lower-alpha;
	}
`;

export const TPWrapper = styled.div`
	font-size: 14px;
	margin: 0 0 20px;
	color: ${({ theme }) => theme.grayScaleColor};
	opacity: 0.7;

	& a {
		color: ${({ theme }) => theme.grayScaleColor};
		text-decoration: underline;
	}

	& ul {
		list-style-type: disc;
	}
	& .listUpperRoman {
		list-style-type: upper-roman;
	}
	& .listLowerAlpha {
		list-style-type: lower-alpha;
	}
`;

export const ErrorMessage = styled(Body)`
	color: ${({ theme }) => theme.errorColor};
	font-size: 12px;
`;
